.preferences-wrap {
    max-width: rem-calc(400);
    margin: 0 auto rem-calc(120);

    @include media-query(small) {
        width: calc(100vw - #{rem-calc(30px)});
    }

    .form-intro__title {
        margin: rem-calc(7) 0 rem-calc(49);
        font-family: $font-family-heavy;
    }

    .form-intro__sub-title {
        margin-bottom: rem-calc(16);
    }

    .form-section {
        margin-bottom: rem-calc(24);
    }

    .form-custom-checkbox {
        border-bottom: 2px solid $color-black;
        margin-bottom: rem-calc(20);
        padding-bottom: rem-calc(30);
        .custom-checkbox {
            display: flex;
            align-items: center
        }
    }
    .callout-small {
        margin: rem-calc(22) 0 0;
        text-align: center;
        width: 100%;
    }
}
