/**
 * TABLE OF CONTENTS
 *
 * COMPONENTS
 * footerCommons...................Contains footer global styles.
 * footerLinks.....................Footer links.
 *
 */

/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/
@import "./footerCommons";
@import "./footerBanners";
