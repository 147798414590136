.footer__banners {
    --banner-item-width: #{rem-calc(216)};

    [lang="fr"] & {
        --banner-item-width: #{rem-calc(236)};
    }

    grid-template-columns: repeat(4, var(--banner-item-width));
    gap: rem-calc(64);
    justify-content: center;

    @include media-query(medium-up-to-mlarge) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include media-query(small-up-to-medium) {
        grid-template-columns: repeat(2, var(--banner-item-width));
        gap: rem-calc(32) rem-calc(64);
    }

    @include media-query(small) {
        gap: rem-calc(16);
        justify-content: flex-start;
    }

    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
}

    .footer__banners-item {
        text-align: center;
    }

    svg.footer__banner-icon {
        width: rem-calc(24);
        height: rem-calc(24);
    }

    .footer__banner-heading {
        font-size: rem-calc(16);
        font-weight: $font-weight-normal;

        [lang="fr"] & {
            font-size: rem-calc(14);
        }
    }
