@import '~app_accelerator_core/base/overlays';

@keyframes showBlock {
    to {
        visibility: visible;
        opacity: 1;
    }
}


@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.set--overlay:not(.header-container):after,
.page:after,
.header:after {
    background-color: transparent;
}

.loader.set--visible {
    @include set-invisible();
    animation: showBlock 0s 2s forwards;

    .refinement-bar & {
        animation-delay: 0s;

        @include media-query(small) {
            position: fixed;
        }
    }

    &:before {
        animation: rotate 0.8s infinite linear;
        border-color: $color-black transparent $color-black $color-black;
        border-style: solid;
        border-width: rem-calc(4);
        border-radius: 50%;
        display: block;
        height: rem-calc(45);
        width: rem-calc(45);
        -webkit-animation: rotate 0.8s infinite linear;
        content: '';
    }

}

.loader-indicator {
   display: none;
}

.show-confirmation-message {
    @include fixed(left 0 bottom 0);

    width: 100%;
    z-index: 4;
    height: auto;

    @include media-query(small-up) {
        .set--fwb {
            width: rem-calc(414);
        }
    }

    &.fadeout {
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        transition: z-index 1s, visibility 500ms, opacity 500ms $transition-ease-out;
    }

    .confirmation-message-container {
        position: relative;

        &.set--fwb .confirmation-message-alert {
            color: $color-white;
            background: $color-fwb-blue;
            padding: rem-calc(16);
        }

        .confirmation-message-alert {
            position: relative;
            text-align: left;
            background: $color-quickadd-bg;
            margin: 5px 0;
            padding: rem-calc(8) rem-calc(16) rem-calc(10);

            &.fadeout {
                opacity: 0;
                visibility: hidden;
                transition: visibility 500ms, opacity 500ms $transition-ease-out;;
            }
        }

        @include media-query(small) {
            width: 100%;

            .confirmation-message-alert {
                margin-bottom: 0;
            }
        }
    }

    .confirmation-message__logo {
        width: rem-calc(88);
        flex-shrink: 0;
    }

    .confirmation-message__close {
        top: rem-calc(8);
        height: rem-calc(32);
        z-index: 1;

        &.window-modal__close {
            right: 0;
            &:after {
                height: rem-calc(12);
                width: rem-calc(12);
            }
        }
        @include media-query(small-up) {
            top: rem-calc(3);
        }
    }

    .confirmation-message__text {
        line-height: rem-calc(18);

        @include media-query(small) {
            width: 90%;
        }
    }

    .set--fwb .confirmation-message__text {
        line-height: rem-calc(22);

        @include media-query(small) {
            width: 100%;
        }
    }

    @include media-query(small) {
        height: auto;
        z-index: 2;
        display: flex;
        align-items: center;
    }

    @include media-query(small-up) {
        max-width: rem-calc(414);
        bottom: rem-calc(64);
        right: rem-calc(30);
        left: unset;
    }
}


.notification__alert {
    z-index: 4;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: rem-calc(8) rem-calc(16);
    background-color: $color-error-light;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &.notification__alert--confirmation {
        background-color: $color-quickadd-bg;
    }

    &.notification__alert--checkout,
    &.notification__alert--giftcard,
    &.notification__alert--pdp,
    &.notification__alert--address {
        position: relative;
        bottom: unset;
        right: unset;
    }

    &.notification__alert--giftcard,
    &.notification__alert--pdp,
    &.notification__alert--address {
        max-width: 100%;
    }

    .notification__alert-close {
        line-height: 1em;
        cursor: pointer;
    }

    @include media-query(small) {
        [data-action="Search-Show"] & {
            z-index: 10001;
        }

        &.notification__alert--giftcard {
            z-index: 2;
        }
    }

    @include media-query(medium-up) {
        max-width: rem-calc(400);
        width: 100%;
        bottom: rem-calc(64);
        right: rem-calc(32);
        left: unset;
    }
}
