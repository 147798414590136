
.quickadd__main--mobile {
    padding: 0 rem-calc(32) rem-calc(32);
    background-color: $color-white;
}

.quickadd__attribute-list {
    margin: rem-calc(8) rem-calc(-2);
}

.quickadd__mobile-button-container {
    flex-grow: 1;
    flex-basis: 100%;

    &:not(:last-of-type) {
        padding-right: rem-calc(4);
    }
}

.quickadd__heading {
    padding: rem-calc(16) rem-calc(32) rem-calc(8);

    .price--formated {
        display: none;
    }

    .price--reduced {
        display: inline-block;
    }
}

.quickview__name {
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    visibility: visible;
}

.quickadd--mobile {
    display: flex;
    flex-direction: column;
    height: 100%;

    .product-tile__media-container {
        height: 100%;
    }

    .form-actions {
        margin-top: 0.5rem;
    }

    .size-type--one-size {
        .quickadd__mobile-button-container {
            .product-attribute__anchor--mobile-quickadd {
                width: calc(100% / 6);
            }
        }
    }
}

.quickadd__image-container {
    flex-basis: 100%;
    flex-shrink: 1;
    min-height: 0;
}

    .quickadd__anchor {
        height: 100%;
    }

    .product-gallery__scroll {
        position: relative;
        display: flex;
        height: 100%;
        &::-webkit-scrollbar {
            display: none;
        }

        .product-gallery__img {
            height: 100%;
            width: auto;
            flex-shrink: 0;
            &:not(:last-child) {
                margin-right: rem-calc(4);
            }
        }
    }

.quickadd__footer {
    [data-has-waitlist-only] &,
    .product-add__estimated-date {
        display: none;
    }

    .button--primary {
        height: rem-calc(56);
    }
}

.icon--quickadd-arrow {
    &-prev, &-next {
        width: rem-calc(32);
        height: rem-calc(32);
        @include vertical-align($transform: true);

        &.slick-disabled {
            opacity: 0;
        }
        .icon {
            overflow: visible;
            width: rem-calc(8);
            height: rem-calc(16);
        }
    }

    &-prev {
        left: rem-calc(-32);
    }

    &-next {
        right: rem-calc(-32);
    }
}

.product-attribute__list--quickadd {
    .product-attribute {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }
    }

    .product-attribute--type-anchor,
    .product-attribute--type-swatch {
        .product-attribute__contents {
            margin: -.25rem;
        }
    }

        .product-size-attribute__anchor {
            border: 1px solid transparent;
            padding: rem-calc(4) rem-calc(5) rem-calc(1);
            margin: 0 rem-calc(5) rem-calc(5);
            font-size: rem-calc(12);
            line-height: 1em;
            height: rem-calc(32);
            white-space: nowrap;

            .product-grid--view-grid & {
                @include media-query(xmedium-up) {
                    padding-left: rem-calc(10);
                    padding-right: rem-calc(10);
                }
            }

            .product-grid--view-feed & {
                padding-left: rem-calc(10);
                padding-right: rem-calc(10);

                @include media-query(xmedium-up) {
                    font-size: rem-calc(16);
                }
            }

            &.unselectable:not(.waitlist) {
                cursor: default;
                color: $color-grey-dark;
                text-decoration: line-through;

                &:hover {
                    border: rem-calc(1) dashed $color-grey-dark;
                }
            }

            &.waitlist {
                color: $color-grey-dark;
            }

            &:hover:not(.unselectable),
            &.waitlist:hover {
                border-color: $color-grey-dark;
            }
        }

        .product-attribute__swatch,
        .product-attribute__anchor {
            margin: .25rem;
        }

        .product-attribute__size-chart {
            display: none;
        }

        .product-attribute__head {
            justify-content: center;
            font-size: rem-calc(12);
            margin-bottom: rem-calc(10);

            .product-grid--view-feed & {
                @include media-query(xmedium-up) {
                    font-size: rem-calc(16);
                }
            }
        }

        .product-attribute__contents {
            width: 100%;
            display: grid;
            justify-items: center;
            grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));

            .product-grid--view-grid & {
                grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
            }
        }

    // Alpha size group
    [class*="size-group--alpha"],
    [class*="size-type--alpha"] {
        .product-attribute__contents {
            .product-grid--view-feed & {
                display: flex;
                justify-content: center;
            }

            @include media-query(xmedium-up) {
                display: flex;
                justify-content: center;

                .product-grid--view-grid & {
                    .product-size-attribute__anchor {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }

            @include media-query(large-up) {
                .product-grid--view-grid & {
                    .product-size-attribute__anchor {
                        margin: 0 rem-calc(5) rem-calc(5);
                    }
                }
            }
        }
    }

    // Numeric size group
    [class*="size-group--numeric"] {
       // UK sizing
       &.size-type--uk {
            .product-attribute__contents {
                grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
            }

            // Petite sizing + UK sizing
            &.size-type--petite {
                .product-attribute__contents {
                    .product-grid--view-feed & {
                        grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
                    }
                }
            }
        }
    }

    // Shoes size group
    .size-group--shoes {
       // UK sizing
       &.size-type--uk {
            .product-attribute__contents {
                grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
            }
        }
    }
}

// REF TODO TF: To be Updated - tem using one image only for Mobile Quick Add
.quickadd__image-container {
    .tile-image-primary {
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

.waitlist-heading,
.waitlist-checkbox,
.waitlist-callout {
    [data-has-waitlist-only] & {
        display: none;
    }
}

.waitlist-heading .icon {
    height: rem-calc(11);
    width: rem-calc(11);
}

.quickadd__waistlist--mobile {
    .form-group {
        margin-bottom: rem-calc(8);
    }

    [data-floating-label] {
        .form-control {
            height: rem-calc(56);
            padding-left: rem-calc(16);
        }

        .form-control-label {
            left: rem-calc(16);
        }

        &:not(.set--floating-label) {
            .form-control-label {
                top: rem-calc(22);
            }
        }
    }

    .waitlist-checkbox {
        margin-bottom: rem-calc(16);
    }

    .form-check-label--custom {
        flex-shrink: 0;

        & + label {
            margin-left: rem-calc(8);
        }
    }

    .button--primary {
        height: rem-calc(56);
    }
}
