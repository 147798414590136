.form-switch {
    display: flex;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    vertical-align: center;
}

.form-switch i {
    border-radius: rem-calc(18);
    display: inline-block;
    height: rem-calc(22);
    margin-right: .5rem;
    position: relative;
    transition: all 0.3s linear;
    vertical-align: text-bottom;
    width: rem-calc(40);
}

.form-switch i::before {
    @include absolute(left 0);
    background-color: $color-grey-4;
    border-radius: rem-calc(11);
    content: "";
    height: rem-calc(22);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
    width: rem-calc(40);
}

.form-switch i::after {
    @include absolute(left 0);
    background-color: #fff;
    border-radius: rem-calc(11);
    content: "";
    height: rem-calc(17);
    transform: translate3d(rem-calc(2), rem-calc(2), 0);
    transition: all 0.2s ease-in-out;
    width: rem-calc(17);
}

.form-switch:active i::after {
    width: rem-calc(28);
    transform: translate3d(rem-calc(5), rem-calc(4), 0);
}

.form-switch:active input:checked + i::after {
    transform: translate3d(rem-calc(16), rem-calc(2), 0);
}

.form-switch input {
    display: none;
}

.form-switch input:checked + i {
    background-color: $color-black;
}

.form-switch input:checked + i::before {
    transform: translate3d(rem-calc(18), rem-calc(2), 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
    transform: translate3d(rem-calc(21), rem-calc(2), 0);
}
.form-switch span {
    padding-top: rem-calc(2);
}
