
.minicart__checkout-header {
    display: flex;
    flex-direction: row;
    gap: rem-calc(8);
    opacity: 0;
    animation: 0.3s fade-in 1s linear forwards;

    .minicart__checkout-action {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .button--minicart-applepay {
        width: 0;
        opacity: 0;
        margin-left: rem-calc(-8);

        &.StripeElement {
            margin: 0;
            opacity: 1;
            width: 100%;
            transition: width 0.2s ease-in;
        }
    }
}

.product-line-item.product-line-item--minicart {
    margin-bottom: rem-calc(46);
}

.atc-confirmation__checkout-buttons--animate {
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
    opacity: 0;
    animation: 0.3s fade-in 1s linear forwards;

    .button--atc-applepay,
    .button--google-pay {
        height: 0;
        opacity: 0;

        &.StripeElement {
            opacity: 1;
            height: rem-calc(56);
            transition: height 0.2s ease-in;
        }
    }
}

.minicart__checkout-action {
    &.button--apple-pay {
        &, &:hover, &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
    .icon--shopping-bag {
        width: rem-calc(24);
        height: rem-calc(24);
        padding: rem-calc(4);
        margin-top: rem-calc(-4);
    }
    .icon--arrow-right {
        width: rem-calc(24);
        height: rem-calc(24);
        padding-bottom: rem-calc(2);
    }
}

.minicart__checkout-action-text {
    line-height: rem-calc(21);
    margin-left: rem-calc(6);
    margin-top: rem-calc(2);
}

.header__minicart-overlay.utility-overlay {
    border: 0;
    padding: 0;

    .line-item-quantity-wrapper {
        display: flex;
        flex-direction: column;
        padding-right: rem-calc(0);

        .line-item-quantity {
            position: relative;
            justify-content: space-between;
        }

        .price {
            .strike-through {
                margin-left: 0;
            }
            .price--reduced {
                margin-right: rem-calc(8)
            }
        }
    }

    .final-sale__label {
        font-size: rem-calc(12);
    }
    .final-sale {
        line-height: rem-calc(18);
    }

    .refDebug__label {
        font-size: rem-calc(12);
    }
    .refDebug {
        line-height: rem-calc(18);
    }

    .line-item__info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .product-line-item__qty-label {
        font-size: rem-calc(16);
        height: rem-calc(32);
        position: absolute;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
            text-decoration: underline;
        }
    }
}

    .header__minicart-overlay-inner {
        border-left: 1px solid $color-grey-0;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        padding: 0 rem-calc(16);
        justify-content: space-between;

        .utility-overlay__header {
            border-bottom: 0;
            padding: rem-calc(24) 0;
        }

        .utility-overlay__footer {
            padding-bottom: 0;
            position: sticky;
            bottom: 0;
        }

        .utility-overlay__footer-section {
            border: 0;
            background-color: $color-sand;
            margin-left: rem-calc(-16);
            margin-right: rem-calc(-16);
            padding: 0 rem-calc(16) rem-calc(16);
        }

        .product-list {
            padding: 0 0 0 rem-calc(5);
        }

        .tab-content {
            margin-right: rem-calc(-30);
        }
    }

    .final-sale:not(.line-item-header) {
        color: $color-error;
    }

    .cart__empty-tabs {
        display: flex;
        flex-direction: column;

        .product-list {
            @include media-query(small) {
                padding-right: 0;
            }
        }
    }

        .cart__empty-headings {
            margin: 0 auto rem-calc(20);
            width: 100%;
        }

            .cart__empty-tabs__item.tab-nav__item {
                padding: 0 0 0 rem-calc(15);
                text-align: left;
                width: 50%;

                &:first-child {
                    text-align: right;
                    padding: 0 rem-calc(15) 0 0;
                }

                &:first-child:last-child {
                    text-align: center;
                }

                &.toggle--active {
                    text-decoration: none;
                }
            }

        .cart__empty-tabs__text {
            border-bottom: 2px solid transparent;

            .toggle--active > & {
                border-bottom-color: $color-black;
            }
        }

.header-minicart-footer {
    margin: 0 rem-calc(-20);
    padding: 0 rem-calc(20);
}

.header__minicart-callout {
    &:not(:empty) {
        padding: rem-calc(16) rem-calc(16) rem-calc(8);
        background-color: $color-beige;
        margin-left: rem-calc(-16);
        margin-right: rem-calc(-16);
    }

    .html-block-list__item,
    .html-block-list__item:not(:last-child) {
        margin-bottom: rem-calc(6);
    }

    .icon {
        min-width: rem-calc(25);
    }

    .icon--cloud {
        width: rem-calc(24);
        height: rem-calc(24);
        margin-bottom: rem-calc(-6);
    }

    section.html-block-list.gutter--normal {
        padding-left: 0;
    }
}

// Add to Cart Confirmation
.atc-confirmation__item {
    .product-line-item__image-wrap {
        width: rem-calc(60);
        flex-shrink: 0;
    }
}

.atc-confirmation__usp-slots {
    opacity: 0;

    &.fade-in {
        opacity: 100%;
        transition: opacity 0.2s ease;
    }

    p {
        text-align: center;
    }

    .icon,
    [data-basket-id] {
        display: none;
    }

    section {
        padding: 0;
    }
}

.atc-confirmation__oos-error-message {
    &:not(.toggle--active) {
        display: none;
    }
}

[data-attr="xgen-cart-flyout-recs"] {
    .module-container__title {
        font-family: $font-family-sans;
        font-size: 1rem;
        padding-top: 2rem;
    }

    .product-promotions__item,
    .product-tile__colors-counter {
        display: none;
    }

}

.minicart__checkout-action.disabled {
    cursor: default;
}

@include media-query(small) {
    .header__minicart-overlay-inner {
        .tooltip__final-sale .tooltip__content.set--tooltip-active.set--center {
            left: rem-calc(-88);
        }
    }

    .cart__empty-tabs__item.tab-nav__item {
        padding: 0;
        margin-left: .75rem;
        margin-right: 0;

        &:first-child {
            padding: 0;
            margin-left: 0;
            margin-right: .75rem;
        }
    }

    [data-attr="xgen-cart-flyout-recs"] {
        .product-tile__mobile-quickadd-button {
            display: none;
        }
    }
}

@include media-query(small-up) {
    .header__minicart-overlay {
        width: rem-calc(440);

        &.utility-overlay {
            .line-item-quantity-wrapper {
                padding-right: rem-calc(16);
            }
        }
    }
}

.refDebug {
    font-size: rem-calc(12);
    margin: rem-calc(4);
}
