
.utility-overlay {
    @include fixed(top 0 right 0);

    display: flex;
    flex-direction: column;
    z-index: 11;
    height: 100%;
    background-color: $color-panel-background;
    border-left: 2px solid $color-black;
    padding: 0 1.25rem 0 1.25rem;
    cursor: default; //Resets cursor when used in combination with an overlay.

    &.toggle--active {
        transition: transform $transition-speed-normal $transition-ease-bezier,
                    visibility 0s;
    }

    &:not(.toggle--active) {
        visibility: hidden;
        transform: translateX(100%);
        transition: transform $transition-speed-fast $transition-ease-in,
                    visibility 0s $transition-ease-in $transition-speed-fast;
    }
}


    .utility-overlay__header,
    .utility-overlay__footer {
        flex-shrink: 0;
    }

    .utility-overlay__header {
        position: relative;
        padding: rem-calc(28) 0 rem-calc(13);
        border-bottom: solid 1px $color-black;
    }
        .utility-overlay__error, .cart-error-messaging {
            .form-invalid-feedback {
                display: flex;
                align-items: flex-start;
                margin: rem-calc(10) 0 rem-calc(7);
                padding: rem-calc(15);
                background: $color-error-light;
                color: $color-primary;

                .icon {
                    margin-right: rem-calc(10);
                    line-height: 1.2em;
                }

                &.form-invalid-feedback--slim,
                .header__minicart-overlay-inner & {
                    padding: rem-calc(4);
                    font-size: rem-calc(12);
    
                    .icon {
                        margin: 0 rem-calc(6) 0 rem-calc(3);
                        height: rem-calc(16);
                        width: rem-calc(10);
                    }
                }
            }

            .notification__alert-close {
                display: none;
            }    
        }

    .utility-overlay__line-items {
        @include scrollbar;

        flex-shrink: 1;
        overflow: auto;
        overflow-x: hidden;
        margin-right: rem-calc(-15); // in order to get scrollbar styled well
        padding-right: rem-calc(15);
        -webkit-overflow-scrolling: touch;
    }

    .cart-summary__nav {
        display: flex;
        flex-direction: column;
        list-style: none;
        font-size: rem-calc(22);
        padding: rem-calc(10) 0;

        .cart-summary__nav-item {
            a:hover {
                border-bottom: rem-calc(2) solid $color-black;
            }

        }
    }

    .utility-overlay__footer {
        margin-top: 0;
        padding-bottom: rem-calc(15);
    }

        .utility-overlay__footer-section {
            padding-left: 0;
            padding-right: 0;
            border-top: solid rem-calc(1) $color-black;
        }

        .utility-overlay__footer-promo {
            padding: rem-calc(13) 0;
        }

        .utility-overlay__footer-total {
            margin: rem-calc(15) 0 rem-calc(10);
        }

        .utility-overlay__footer-message {
            margin-top: 1em;
        }

.utility-overlay__header-close {
    margin-left: rem-calc(10);
}