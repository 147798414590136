@import "~app_accelerator_core/lib/carousels";

.slick-arrow {
  .icon {
    width: rem-calc(14);
    height: rem-calc(24);

    .slider--arrows-size-small & {
      width: rem-calc(10);
      height: rem-calc(16);  
    }

    .slider--arrows-size-large & {
      width: rem-calc(21);
      height: rem-calc(36);

      @include media-query(small) {
        width: rem-calc(14);
        height: rem-calc(24);
      }
    }
  }

  @include media-query(small) {
    width: auto;
    padding: 0 rem-calc(12);

    .slider--arrows-size-large & {
      width: auto;
      height: rem-calc(50);
    }
  }
}

.slider--hide-overflow { 
  &:not(.slick-initialized) {
    display: flex;
    overflow: hidden;
  }
}

@include media-query(small) {
  .module-carousel__item .hero__content-wrap {
    padding-left: rem-calc(40);
    padding-right: rem-calc(40);
  }
}
