@import "~app_accelerator_core/modules/modals";

.window-modal {
    background-color: rgba(255, 255, 255, 0.9);
}

.window-modal__content {
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #000000;
}

.window-modal__close {
    top: rem-calc(10);
    right: rem-calc(10);

    &:hover {
        opacity: 1;
    }

    &:after {
        content: url(get-icon(close, $color-body-copy));
    }
}


.modal--generic,
.modal--auth {
    .window-modal__content {
        background: $color-white;
        padding: rem-calc(50) rem-calc(100) rem-calc(44);
        width: auto;
        max-width: rem-calc(555);
        height: auto;
        border: 1px solid $color-black;
    }
}

.modal--generic {
    &.irc {
        .window-modal__content{
            max-width: fit-content;
            padding: rem-calc(64) rem-calc(32) rem-calc(32);

            @include media-query(small) {
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
                height: 100vh;
                border: none;
            }

            @include media-query(small-up) {
                .button {
                    flex-basis: 50%;
                }
            }

            .irc-nudge__images {
                overflow-x: scroll;
                scroll-behavior: smooth;
                
                @include media-query(small) {
                    flex-direction: row;
                    margin: 0 rem-calc(-16);   
                }
                
                &::-webkit-scrollbar {
                    display: none;
                }        
            }

            .product-line-item__image {
                height: rem-calc(266);
                width: auto;
                max-width: 100%;
                margin: 0 rem-calc(2) 0 0;

                &:last-child {
                    margin: 0;
                }
                
                @include media-query(small) {
                    height: rem-calc(250);
                }
            }
        }
    }
}

.modal--promo.modal--generic.window-modal:not(.modal--sizequide) {
    .window-modal__content {
        min-height: unset;
        padding: rem-calc(24) rem-calc(20);
        border: 1px solid $color-black;
        max-width: rem-calc(400);
    }
    .window-modal__close {
        top: rem-calc(19.5);
    }
}

.modal--payment.modal--generic {
    .window-modal__content {
        width: 100%;
        min-height: unset;
        border: 0;
        border-top: 1px solid $color-black;
        max-width: 100%;
        padding: rem-calc(24) rem-calc(32);
        [data-floating-label].set--floating-label .form-control-label {
            top: rem-calc(10);
        }
        [data-floating-label] {
            .form-control {
                height: rem-calc(56);
                font-size: rem-calc(16);
                padding: rem-calc(22) rem-calc(10) rem-calc(2);
            }
            .form-control-label {
                font-size: rem-calc(16);
                top: rem-calc(20);
            }
        }
        .payment-elements-input {
            height: rem-calc(56);
            padding: rem-calc(18) rem-calc(10) rem-calc(2);
        }

        .modal__content {
            margin-top: rem-calc(16);
        }

        .form-custom-checkbox {

            .make-default-payment {
                margin-top: rem-calc(2);
                margin-left: rem-calc(4);

                .form-group.custom-checkbox {
                    display: flex;

                    .form-check-label--small {
                        height: rem-calc(12);
                        width: rem-calc(12);
                        min-height: rem-calc(12);
                        margin-top: rem-calc(5);

                        &:before, &:after {
                            height: rem-calc(12);
                            width: rem-calc(12);
                            padding: rem-calc(2) 0 0;
                        }
                    }
                }
            }

            .custom-checkbox {
                margin-bottom: 0;
            }

            .custom-control-label {
                margin-left: rem-calc(8);
            }
        }

        .window-modal__close {
            top: rem-calc(28);
            right: rem-calc(28);
            width: rem-calc(16);
            height: rem-calc(16);

        }
        .button {
            padding: 0;
        }
        .modal__action {
            margin-top: rem-calc(32);
            height: rem-calc(56);
        }

        .modal__action-wrapper {
            padding-left: rem-calc(4);
            padding-right: rem-calc(4);
        }

        @include media-query(small-up) {
            border: 1px solid $color-black;
            max-width: rem-calc(438);
        }
    }

    @include media-query(small) {
        .window-modal__content {
            .modal__actions.row {
                margin-top: rem-calc(24);
                flex-direction: column-reverse;

                .modal__action.button {
                    margin-top: rem-calc(8);
                    font-size: rem-calc(16);
                }
            }
        }
    }
}
.modal--auth {

    .login-header {
        font-size: rem-calc(18);
        display: block;
        margin-bottom: rem-calc(22);
        letter-spacing: $letter-spacing-base;
    }

    .login-button {
        margin-bottom: rem-calc(30);
    }

    .login-header__asset {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.modal--generic {

    .modal__reset-password {
        text-align: center;
    }
}

.waitlist--error {
    color: $color-error;
    ::before {
        border: 1px solid $color-error !important;
    }
}

.modal--vip-modal {
    .window-modal__content {
        @include media-query(small-up) {
            width: rem-calc(840);
            border: 1px solid $color-black;
            
            .account__fwb-hero-logo {
                max-width: rem-calc(300);
            }
        }

        @include media-query(small) {
            min-height: 100vh;

            .account__fwb-hero-logo {
                max-width: rem-calc(150);
            }

            .window-modal__close{
                &:after {
                    content: url(get-icon(close, $color-white));
                }
            }
        }
    }
}

.modal--product-zoom {
    .window-modal__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .window-modal__close {
        @include fixed(top rem-calc(15) right rem-calc(15));

        background-color: white;
        width: rem-calc(32);
        height: rem-calc(32);
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

.modal--remove-product {
    .window-modal__content {
        padding-top: 6rem;
        width: 55%;
        text-align: center;
        margin: 0 auto;
    }
}

#modal-waitlistModal {
    .window-modal__content {
        max-width: rem-calc(600);
        border: 1px solid $color-grey-6;

        @include media-query(medium-up) {
            padding: rem-calc(50) rem-calc(85) rem-calc(44);

            .waitlist-sms-subheader {
                margin: auto rem-calc(-60);
            }
        }

        @include media-query(small) {
            height: 100vh;
        }
    }

    .waitlist-form {
        margin: rem-calc(22) auto rem-calc(16) auto;
        max-width: rem-calc(383);

        .form-group {
            margin-bottom: rem-calc(8);
        }
    }

    .waitlist-callout {
        margin: 0 rem-calc(35);
    }

    .form-custom-checkbox {
        .custom-checkbox {
            display: flex;
            align-items: center;

            .custom-control-label {
                user-select: none;
            }
        }
    }

    .form-waitlist-sms .form-check-label {
        align-self: baseline;
    }
}

#modal-returnExchangeModal {

    &.modal--payment .window-modal__content{
        @include media-query(medium-up) {
            max-width: rem-calc(464);
            padding-top: rem-calc(52);
            border: 1px solid $color-black;
        }

        @include media-query(small) {
            min-height: 100vh;
            padding-top: rem-calc(60);
        }
    }

    .window-modal__content {
        @include media-query(medium-up) {
            max-width: rem-calc(600);
            padding: rem-calc(68) rem-calc(32) rem-calc(24);
            border: 1px solid $color-grey-1;
        }

        @include media-query(small) {
            min-height: 100vh;
            padding: rem-calc(76) rem-calc(20) rem-calc(24);
        }
    }
}


.modal--mobile-quickadd {
    background-color:rgba($color-white, 0.7);

    .window-modal__content {
        height: 80vh;
        width: 100%;
    }

    .window-modal__close {
        height: rem-calc(40);
        width: rem-calc(40);
        top: rem-calc(6);
        right: rem-calc(6);
        padding: rem-calc(10);

        &:after {
            background-color: $color-white;
            height: rem-calc(20);
            width: rem-calc(20);
            padding: 0 rem-calc(4);
        }
    }
}
.modal--hemster {
    .window-modal__content {
        padding: rem-calc(52) rem-calc(32);
        margin: auto;

        @include media-query(small) {
            min-height: 100%;
            border: none;
            padding: rem-calc(60) rem-calc(32)  rem-calc(24);
        }

        .hemster-modal {
            .hemster-logo {
                margin: 0 auto rem-calc(40);
                max-width: rem-calc(125);
            }

            .product-line-item__details {
                justify-content: center;

                @include media-query(small) {
                    justify-content: flex-start;
                    padding-left: rem-calc(20);
                }

                .product-line-item__image {
                    max-width: rem-calc(100);

                    @include media-query(small) {
                        max-width: rem-calc(120);
                    }
                }

                .product-line-item__attributes {
                    margin-left: rem-calc(16);
                }
            }

            .modal-cta {
                margin: rem-calc(24) auto rem-calc(32);
            }

            .modal-disclaimer {
                text-align: center;
            }
        }
    }
}

@include media-query(small-up) {
    .modal--address-delete {
        .window-modal__content {
            max-width: 27rem;
        }
    }
}


.modal--store-locator {
    .window-modal__content {
        padding: rem-calc(25);
    }

    .window-modal__close {
        right: rem-calc(18);
        top: rem-calc(18);

        &::after {
            height: rem-calc(19);
            width: rem-calc(19);
        }
    }

    .store-locator__find,
    .store-locator__save,
    [data-floating-label] .form-control {
        height: rem-calc(56);
    }

    [data-floating-label]:not(.set--floating-label) .form-control-label {
        top: rem-calc(20);
    }

    .store-locator__action {
        text-align: right;
    }

    .store-locator__save {
        margin-left: auto;
        margin-top: rem-calc(32);
        width: auto;

        &[disabled] {
            display: none;
        }
    }

    .store-locator__no-results {
        max-width: rem-calc(382);
        margin: 0 auto rem-calc(40);
    }

    .store-locator__form {
        margin-bottom: rem-calc(32);
    }

    .store-locator__address-error {
        display: none;
        font-size: rem-calc(12);
        padding-left: rem-calc(16);
    }

    .store-locator__address.set--error {
        color: $color-error;

        .store-locator__address-error {
            display: block;
            margin-top: rem-calc(4);
        }

        .store-locator__address-field {
            background: $color-error-light;
            border-color: $color-error;
        }
    }

    .store-locator__detect-location {
        margin-top: rem-calc(16);
    }
}

.modal--smarty {
    .window-modal__content {
        max-width: rem-calc(438);
        padding: rem-calc(24) rem-calc(32);
        min-height: 0;
    }
}

@include media-query(small-up) {
    .modal--fabric-detail {
        overflow: hidden;

        .window-modal__content {
            width: 100%;
            max-width: rem-calc(540);
            padding: rem-calc(72) rem-calc(32) rem-calc(32);
            min-height: 0;
            position: fixed;
            right: -100%;
            top: 0;
            bottom: 0;
            border: none;
            transition: right 0.5s ease;
        }

        &.modal-active {
            .window-modal__content {
                right: 0;
                transition: right 0.5s ease;
            }
        }
    }

    .modal--slide-in-desktop {
        overflow: hidden;
        background-color: transparent;
        opacity: 100%;
        visibility: visible;

        .window-modal__content {
            width: 100%;
            max-width: rem-calc(448);
            border: solid $color-black;
            border-width: 0 0 0 rem-calc(2);
            padding: rem-calc(24) rem-calc(32) rem-calc(32);
            min-height: 0;
            position: fixed;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 100%;
            visibility: visible;
            transform: translateX(100%);
            transition: transform $transition-speed-normal $transition-ease-bezier;
        }

        .window-modal__close {
            width: rem-calc(32);
            height: rem-calc(32);
            right: rem-calc(32);
            top: rem-calc(24);

            &:after {
                content: url(get-icon(close-lg, $color-body-copy));
                width: rem-calc(32);
                height: rem-calc(32);    
            }
        }

        &.modal-active {
            .window-modal__content {
                transform: translateX(0);
                transition: transform $transition-speed-normal $transition-ease-bezier;
            }
        }
    }
}


@include media-query(small) {
    .modal--store-locator {
        .window-modal__content {
            border: 0;
            padding: rem-calc(32) rem-calc(16);
        }

        .store-locator__action {
            text-align: center;
        }

        .store-locator__save {
            float: none;
            width: 100%;
        }

        .store-locator__no-results {
            margin: 0;
        }
    }

    .modal--generic.window-modal:not(.modal--sizequide):not(.modal--hemster):not(.modal--payment):not(.modal--smarty):not(.irc):not(.modal--slide-up-mobile) {
        .modal__title {
            text-align: center;
        }

        .window-modal__content {
            padding: rem-calc(50) rem-calc(44) rem-calc(34);
        }
    }

    .modal--auth,
    .modal-requestPasswordReset {
        .window-modal__content {
            height: 100%;
        }
    }

    .window-modal.modal--sizequide {
        align-items: flex-start;
        background: $color-white;
        justify-content: center;

        .window-modal__content {
            margin-top: 0;
        }
    }

    .modal--remove-product .window-modal__content {
        width: 90%;
    }

    .modal--display-top__mobile {
        display: block;
    }

    .modal--promo.modal--generic.window-modal:not(.modal--sizequide) {
        .window-modal__content {
            @include absolute(top unset bottom 0 left 0 right 0);
            margin: 0 auto;
            max-width: unset;
            border: 0;
            border-top: 1px solid $color-black;
        }
    }

    .modal--smarty {
        .window-modal__content {
            padding: rem-calc(24) rem-calc(20);
        }
    }

    .modal--fabric-detail {
        .window-modal__content {
            height: 100vh;
            padding: rem-calc(64) rem-calc(16) rem-calc(24);
            border: none;

            @supports (height: 100dvh) {
                height: 100dvh;
            }  
        }
    }

    .modal--slide-up-mobile {
        background-color: rgba($color-white, 0.7);

        .window-modal__content {
            border-width: rem-calc(1) 0 0;
            padding: rem-calc(24) rem-calc(16);
            width: 100vw;
            max-width: 100vw;
        }

        .window-modal__close {
            width: rem-calc(32);
            height: rem-calc(32);
            right: rem-calc(32);
            top: rem-calc(24);

            &:after {
                content: url(get-icon(close-lg, $color-body-copy));
                width: rem-calc(32);
                height: rem-calc(32);    
            }
        
        }
    }
}

.modal--afterpay {
    .window-modal__content {
        max-width: rem-calc(850);

        @include media-query(medium-up) {
            padding: rem-calc(50) rem-calc(30);
        }
    }

    .afterpay__icon-wrapper {
        text-align: center;

        img {
            max-width: rem-calc(170);
            margin: auto;
        }
    }

    .afterpay__modal-item {
        flex: 1;
    }

    .afterpay__disclaimer {
        color: $color-grey-5;
    }

    @include media-query(medium) {
        .afterpay__modal-copy {
            flex-direction: column;
        }
    }
}

#modal-gated-login {
    .tab-nav {
        flex-direction: column;
    }

    .window-modal__content {
        max-width: rem-calc(804);
        border: 0;
    }

    &.window-modal {
        align-items: flex-start;
    }

    .button--primary {
        background-color: $color-fwb-blue;
        border: 0;

        &:hover:not([disabled]):not(.disabled) {
            background-color: $color-white;
            color: $color-fwb-blue;
            border: 1px solid $color-fwb-blue;
        }
    }

    [data-floating-label] .form-control {
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid $color-black;
    }

    .form-group.form-group--constraints {
        margin-bottom: rem-calc(16);
    }

    .gated-login-title {
        color: $color-fwb-blue;
    }

    .gated-login-content {
        min-height: rem-calc(502);
        background-color: $color-fwb-beige;
    }

    .blocked-user-message {
        background-color: $color-fwb-beige;

        .form-container {
            padding: rem-calc(24) rem-calc(40);
            justify-content: space-between;
        }
    }

    .form-container {
        justify-content: flex-start;
        padding: 0 rem-calc(32) rem-calc(24);
    }

    .flex-child-50 {
        flex: 1 1 50%;
        flex-direction: column;
        display: flex;
    }

    .login-button {
        margin-top: rem-calc(32);
    }

    .form-control-disclaimer {
        margin-top: rem-calc(16);
    }

    @include media-query(small) {
        .window-modal__content {
            margin-top: rem-calc(64);

            &.blocked {
                margin-top: calc(100vh / 4.5)
            }
        }

        .fwb-hero {
            padding: rem-calc(24);

            &.flex-justify-center {
                text-align: center;
            }

            picture, img {
                display: flex;
                align-self: center;
            }
        }

        .scrollbartop {
            width: 100%;
            border-top: 8px solid $color-fwb-blue;
            position: sticky;
            top: 0;
        }

        .modal-body {
            overflow: scroll;
            max-height: calc(100vh - 8rem);
        }

        .blocked-user-message {
            .form-container {
                text-align: center;
                padding-top: rem-calc(16);
                padding-bottom: rem-calc(40);
            }

            .fwb-hero {
                padding: rem-calc(24) rem-calc(24) 0;
            }
        }
    }

    @include media-query(small-up) {
        .window-modal__content.blocked {
            max-width: rem-calc(584);
        }

        .fwb-hero {
            background-color: $color-fwb-blue;
            padding: rem-calc(96) rem-calc(32);
        }

        .blocked-user-message {
            .fwb-hero {
                padding: rem-calc(96) rem-calc(48);
            }
        }

        .form-container {
            padding: rem-calc(56) rem-calc(40);
            max-height: rem-calc(680);
            overflow: scroll;
        }
    }
}

#modal-ref-welcome {
    @include media-query(small-up) {
        .window-modal__content {
            max-width: rem-calc(420);
            padding: 1.5rem;
        }
    }

    .welcome-header {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5;
        margin-bottom: 1rem;
        max-width: unset;
    }

    .welcome-texts {
        margin-bottom: 0;
        font-size: 16px;
        text-transform: none;
        text-align: left;
        letter-spacing: .5px;
        line-height: 1.5;

        .texts-list {
            margin: 0;
            padding: 0;
            list-style: none !important;
            text-align: start;

            li {
                background-position: left 6px;
                background-repeat: no-repeat;
                padding-left: 1.875rem;
                padding-right: 0;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNC43MjAxNUwwLjg4MDkzMiAzLjgzOTczTDQuMzMwNjUgNy4yNzY4NUwxMS4xMDcgMC41TDEyIDEuMzkzMDFMNC4zMzA2NSA5LjA2Mjg3TDAgNC43MjAxNVoiIGZpbGw9ImJsYWNrIj48L3BhdGg+Cjwvc3ZnPg==);
                background-position-x: 3px;
                margin-bottom: 0;
            }
        }
    }

    .welcome-subhead {
        font-size: 1rem;
        font-weight: 500;
        font-family: var(--gle_font_family);
        margin: 1rem 0;
        margin-top: .75rem;
        text-align: start;
        line-height: 1.5;
    }

    .welcome-change-country {
        text-transform: none;
        color: $color-black;
        font-size: 16px;
        background: none;
        border: none;
        text-decoration: underline;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        cursor: pointer;
        font-weight: normal;
        text-align: center;
        margin: 0 auto;
        display: block;
    }

    .welcome-button__primary {
        padding: 1rem;
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}
/*md

# Modals

```html_example

    <a class="body-type--centi link link--underline" title="Generic Modal" href="/on/demandware.store/Sites-reformation-us-Site/default/Account-PasswordResetDialogForm">
         Generic Modal
    </a>

```
 */
