.product-grid__item {
    margin-bottom: rem-calc(32);
}

.product-list__carousel.slider--row {
    &:not(.slick-initialized),
    .slick-list {
        margin-left: rem-calc(-4);
        margin-right: rem-calc(-4);

        &::-webkit-scrollbar {
            display: none
        }

        @include media-query(small-up) {
            margin-left: rem-calc(-16);
            margin-right: rem-calc(-16);
        }
    }

    .slick-arrow {
        width: rem-calc(36);
    }

    .slick-slide:not(.hero) {
        &:not(.slick-active) {
            visibility: hidden;
        }
    }    
}

.product-list__carousel-grid {
    .product-tile__body-section, .price {
        font-size: rem-calc(16);
    }
    .slick-dots {
        margin-top: rem-calc(8);
        button {
            width: rem-calc(16);
            height: rem-calc(16);
        }
    }
    .product-tile__mobile-quickadd-button {
        display: none
    }
}

@include media-query(small) {
    .product-list__carousel-grid {
        .product-tile__anchor {
            margin: rem-calc(2);
        }

        .product-tile__body {
            height: rem-calc(102);
            margin-left: rem-calc(16);
        }
    }

    .notfound__recommendations,
    .notfound__content-tiles {
        margin-right: rem-calc(-15);

        .scrollable {
            &::-webkit-scrollbar {
                display: none
            }
        }

        .col--padding-lg {
            flex-basis: 40%;
        }

        .slider--pre-layout-1:not(.slick-initialized) > *:not(.slick-item) {
            flex-basis: 40%;
        }
    }

    .pdp__recommendations,
    .cart__empty-recommendations {
        padding-right: 0;

        .slider--pre-layout-1:not(.slick-initialized) > *:not(.slick-item) {
            flex-basis: 66%;
        }
    }

    .row--padding-lg:after {
        content: '';
        width: rem-calc(11);
        flex-shrink: 0;
    }

    .pdp__recommendations,
    .cart__empty-recommendations,
    .notfound__recommendations {
        [class*=slider--pre-layout]:not(.slick-initialized) {
            &:after {
                content: '';
                width: rem-calc(11);
                flex-shrink: 0;
            }
        }
    }
}

@include media-query(small-up) {
    .pdp__recommendations {
        max-width: 125rem;
        margin-right: rem-calc(32);
        margin-left: rem-calc(32);
    }
    .product-list__carousel-grid.product-list__carousel .product-tile__colors-container {
        height: rem-calc(21);
    }
}

.category__tile-carousel {
    margin: rem-calc(16) 0 rem-calc(48);

    @include media-query(small-up) {
        width: 100%;
        max-width: calc(100% - #{rem-calc(56)});
    }

    @include media-query(small) {
        overflow-x: scroll;
        scroll-behavior: smooth;
        margin-left: rem-calc(-20);
        margin-right: rem-calc(-20);
        width: 100vw;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .category__tile {
        margin: 0 rem-calc(1);
        flex-shrink: 0;
        flex-grow: 1;
        align-self: flex-start;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .category__tile-img {
        height: rem-calc(266);
        width: auto;
    
        @include media-query(small) {
            height: rem-calc(250);
        }
    }

    .slick-arrow {
        width: rem-calc(8.25);
        right: rem-calc(-24);
    }
}
