

.border-style--dashed {
    border-style: dashed;
}

.flex-justify-evenly {
    justify-content: space-evenly;
}

.bg {
    @include property-modifiers($colors, background-color);
}

.border {
    border-style: solid;
    @include property-modifiers($colors, border-color);
}

.border-t--grey {
    border-top: rem-calc(1) solid $color-grey-line;
}

.relative {
    position: relative;
}

@include generate-enumerated-class(border-width, border-width, 16, 'px');

ol.list__marker--heavy {
    li::marker {
        font-family: $font-family-heavy;
    }
}

@include media-query(small-up) {
    .t-bg {
        @include property-modifiers($colors, background-color);
    }

    .t-border {
        border-style: solid;
        @include property-modifiers($colors, border-color);
    }
}

@include media-query(medium-up) {
    .d-bg {
        @include property-modifiers($colors, background-color);
    }

    .d-border {
        border-style: solid;
        @include property-modifiers($colors, border-color);
    }
}

.overflow--hidden {
    overflow: hidden;
}


@keyframes loading-dot1 {
    0%, 33% {
        opacity: 0;
    }
    34%, 100% {
        opacity: 1;
    }
}
  
@keyframes loading-dot2 {
    0%, 66% {
        opacity: 0;
    }
    67%, 100% {
        opacity: 1;
    }
}
  
@keyframes loading-bar {
    0% {
        opacity: 0.5;
    }

    25% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}
  
/*----------------------------------------------*\
#LOADING DOTS + CHECKMARK ANIMATED BUTTON
\*----------------------------------------------*/

.button--showloading {
    &[data-button-state="loading"],
    &[data-button-state="complete"] {
        pointer-events: none;

        .button_text {
            display: none;
        }        
    }
}

.button_loading-dots {
    position: relative;
    width: rem-calc(5);
    height: rem-calc(5);
    border-radius: 50%;
    background-color: $color-white;
    transform: translateX(rem-calc(-10));
    display: none;

    .button--primary-outline & {
        background-color: $color-black;
    }

    [data-button-state="loading"] & {
        display: inline-flex;
    }

    @media (hover: hover) {
        .button--showloading:hover:not([disabled]):not(.disabled) & {
            background-color: $color-black;
        }

        .button--primary-outline:hover:not([disabled]):not(.disabled) & {
            background-color: $color-white;
        }
    }

    &:before,
    &:after {
        content: '';
        display: inline-flex;
        width: rem-calc(5);
        height: rem-calc(5);
        border-radius: 50%;
        position: absolute;
        background-color: $color-white;
        
        .button--primary-outline & {
            background-color: $color-black;
        }

        @media (hover: hover) {
            .button--showloading:hover:not([disabled]):not(.disabled) & {
                background-color: $color-black;
            }

            .button--primary-outline:hover:not([disabled]):not(.disabled) & {
                background-color: $color-white;
            }    
        }
    }

    &:before {
        right: rem-calc(-10);
        animation: loading-dot1 1s infinite linear;
    }

    &:after {
        right: rem-calc(-20);
        animation: loading-dot2 1s infinite linear;
    }
}

.button_checkmark {
    line-height: 1em;
    display: none;

    [data-button-state="complete"] & {
        display: inline-flex;
    }

    .icon {
        width: rem-calc(20);
        height: rem-calc(20);

        @media (hover: hover) {
            .button--showloading:hover:not([disabled]):not(.disabled) & {
                fill: $color-black;
            }
        }
    }
}

/*md

# Helper classes

## Margin and padding

```html_example
    <div class="styleguide__section-helpers">
        <div>
            <span>margin-l--15</span>
            <span>Margin left 15 px</span>
        </div>
        <div>
            <span>t-margin-l--15</span>
            <span>Tablet and desktop</span>
        </div>
        <div>
            <span>d-margin-l--15</span>
            <span>Desktop only</span>
        </div>
        <div>
            <span>margin-t--20</span>
            <span>Margin top 20 px</span>
        </div>
        <div>
            <span>t-margin-t--20</span>
            <span>Tablet and desktop</span>
        </div>
        <div>
            <span>d-margin-t--20</span>
            <span>Desktop only</span>
        </div>
         <div>
            <span>margin-b--30</span>
            <span>Margin top 30 px</span>
        </div>
        <div>
            <span>t-margin-b--30</span>
            <span>Tablet and desktop</span>
        </div>
        <div>
            <span>d-margin-b--30</span>
            <span>Desktop only</span>
        </div>
        <div>
            <span>margin-r--10</span>
            <span>Margin right 10 px</span>
        </div>
        <div>
            <span>t-margin-r--10</span>
            <span>Tablet and desktop</span>
        </div>
        <div>
            <span>d-margin-r--10</span>
            <span>Desktop only</span>
        </div>
    </div>
```

## Background

```html_example
    <div class="styleguide__section-helpers">
        <div>
            <span>bg--grey-1</span>
            <span class="styleguide__section-color--mini bg--grey-1">Grey-1</span>
        </div>
        <div>
            <span>t-bg--grey-1</span>
            <span class="styleguide__section-color--mini t-bg--grey-1">Grey-1</span>
        </div>
        <div>
            <span>d-bg--grey-1</span>
            <span class="styleguide__section-color--mini d-bg--grey-1">Grey-1</span>
        </div>

        <div>
             <span>bg--grey-2</span>
            <span class="styleguide__section-color--mini bg--grey-2">Grey-2</span>
        </div>
        <div>
            <span>t-bg--grey-2</span>
            <span class="styleguide__section-color--mini t-bg--grey-2">Grey-2</span>
        </div>
        <div>
            <span>d-bg--grey-2</span>
            <span class="styleguide__section-color--mini d-bg--grey-2">Grey-2</span>
        </div>

        <div>
             <span>bg--grey-3</span>
            <span class="styleguide__section-color--mini bg--grey-3">Grey-3</span>
        </div>
        <div>
            <span>t-bg--grey-3</span>
            <span class="styleguide__section-color--mini t-bg--grey-3">Grey-3</span>
        </div>
        <div>
            <span>d-bg--grey-3</span>
            <span class="styleguide__section-color--mini d-bg--grey-3">Grey-3</span>
        </div>

        <div>
             <span>bg--grey-4</span>
            <span class="styleguide__section-color--mini bg--grey-4">Grey-4</span>
        </div>
        <div>
            <span>t-bg--grey-4</span>
            <span class="styleguide__section-color--mini t-bg--grey-4">Grey-41</span>
        </div>
        <div>
            <span>d-bg--grey-4</span>
            <span class="styleguide__section-color--mini d-bg--grey-4">Grey-4</span>
        </div>
    </div>
```

## Width

Possible values: 0 - 100 with interval of 5.

```html_example
    <div class="styleguide__section-helpers styleguide__section-helpers--width">
         <div>
            <span>set--w-80</span>
            <span class="styleguide__section-width set--w-80">80%</span>
        </div>
        <div>
            <span>set--w-60</span>
            <span class="styleguide__section-width set--w-60">60%</span>
        </div>
        <div>
            <span>set--w-40</span>
            <span class="styleguide__section-width set--w-40">40%</span>
        </div>
        <div>
            <span>set--w-70</span>
            <span class="styleguide__section-width set--w-70">70%</span>
        </div>
        <div>
            <span>set--w-50</span>
            <span class="styleguide__section-width set--w-50">50%</span>
        </div>
         <div>
            <span>set--w-30</span>
            <span class="styleguide__section-width set--w-30">30%</span>
        </div>
    </div>
```


## Font-sizes

Possible values: 12, 14, 16, 18, 20, 22, 25, 30, 40, 50

```html_example
    <div class="styleguide__section-helpers">
        <div>
            <span>font-size--12</span>
            <span>Size 12px</span>
        </div>
        <div>
            <span>t-font-size--12</span>
            <span>Tablet and desktop</span>
        </div>
        <div>
            <span>d-font-size--12</span>
            <span>Desktop only</span>
        </div>

        <div>
            <span>font-size--14</span>
            <span>Size 14px</span>
        </div>
        <div>
            <span>t-font-size--14</span>
            <span>Tablet and desktop</span>
        </div>
        <div>
            <span>d-font-size--14</span>
            <span>Desktop only</span>
        </div>

        <div>
            <span>font-size--16</span>
            <span>Size 16px</span>
        </div>
        <div>
            <span>t-font-size--16</span>
            <span>Tablet and desktop</span>
        </div>
        <div>
            <span>d-font-size--16</span>
            <span>Desktop only</span>
        </div>

        <div>
            <span>font-size--18</span>
            <span>Size 18px</span>
        </div>
        <div>
            <span>t-font-size--18</span>
            <span>Tablet and desktop</span>
        </div>
        <div>
            <span>d-font-size--18</span>
            <span>Desktop only</span>
        </div>
    </div>
```
 */
