@import "~app_accelerator_core/components/toggleBox";

/*------------------------------------*\
  #TOGGLE-BOX
\*------------------------------------*/

.toggle-box {
	border-color: $color-black;

	.component-type--collapsibleBox & {
		border-width: rem-calc(1);
	}

	.component-type--collapsibleBox + .component-type--collapsibleBox & {
		border-width: 0 rem-calc(1) rem-calc(1) rem-calc(1);
	}
}

.toggle-box__label {
	justify-content: space-between;
	padding: rem-calc(1) rem-calc(18) 0 rem-calc(12);
	min-height: rem-calc(46);
	background: $color-white;

	&:after {
		display: none;
	}

	.icon {
		line-height: 1em;
		position: relative;
		top: rem-calc(-1);
	}

	&.toggle--active {
		font-weight: normal;

		.icon {
			transform: rotate(45deg);
		}
	}
}

.toggle-box__content {
	&.toggle--active {
		border-top: rem-calc(1) solid $color-black;
		padding-top: rem-calc(20);
		padding-bottom: rem-calc(36);

	}
}
