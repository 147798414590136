@import "~app_accelerator_core/components/header/headerSearch";

.header__search-trigger {
    &.toggle--active {
        .site-search__trigger-search {
            @include set-visible();
        }
    }

    &:not(.toggle--active) {
        .site-search__trigger-close {
            @include set-visible();
        }
    }
}

.site-search__suggestions-list {
    padding-top: 0;
}

.site-search__trigger-close {
    display: none;
}

.site-search__form-wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    .invalid-feedback.set--visible {
        font-size: rem-calc(12);
        position: absolute;
        top: 100%;
    }
}

.site-search__form {
    align-items: baseline;
    background-color: $color-white;
    display: flex;
    height: rem-calc(60);
    top: calc(100% - 1px);
    padding: rem-calc(10) 0 0;
}

.site-search__form .site-search__field {
    border-bottom: 1px solid $color-black;
    padding-top: rem-calc(21);
    padding-bottom: rem-calc(10);
    padding-left: rem-calc(0);
    width: calc(100% - 4.25rem);
    
    &:focus {
        background-color: $color-white !important;
        border-bottom: 1px solid $color-black;
        border-color: $color-black !important;
    }
}

.focus-within .site-search__form,
.set--has-results .site-search__form {
    height: 100vh;
}

.site-search__button {
    position: unset;
    margin-left: rem-calc(20);
    width: 3.5rem;
}

.site-search__suggestions-container {
    &.set--invisible {
        display: none;
    }
}

.site-search__suggestions-list-category {
    margin: rem-calc(-6) 0 0 rem-calc(-8);
    max-width: rem-calc(240);

    .site-search__suggestions-item {
        margin: 0;
    }
}

.site-search__suggestions-category {
    padding: rem-calc(6) 0 rem-calc(2) rem-calc(8);
    width: 100%;
    font-size: rem-calc(16);

    &:focus,
    &:hover {
        outline-style: none;
        background-color: $color-beige;
    }
}

.site-search__suggestions-product-tiles {
    .site-search__suggestions-matches {
        display: flex;

        &::-webkit-scrollbar {
            display: none;
        }

        @include media-query(medium-up) {
            &.align--right {
                justify-content: flex-end;
            }
        }
    }

    .site-search__suggestions-item {
        width: 20%;
        padding-left: rem-calc(16);
        flex-shrink: 0;
    }

    .range {
        display: flex;
    }
    
    .product-tile__name {
        margin: 0;
    }
    
    @include media-query(small-up) {
        .product-tile__body .product__badges .product__badges-item {
            margin-left: 0;
        }
    }
}

.site-search__form-wrapper .form-control-label {
    left: 0;
}


@include media-query(medium) {
    .site-search__form {
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        padding-top: rem-calc(50);
        overflow: hidden;
        position: fixed;
        top: 0;
    }

    .site-search__trigger-icon {
        position: unset;
        margin: 0;
    }

    .header__search-trigger {
        height: auto;
        width: auto;
    }

    .site-search__form-wrapper {
        margin: 0 rem-calc(20);
        width: calc(100% - #{rem-calc(40)});
    }
    
    .site-search__suggestions-list {
        padding-bottom: rem-calc(24);
    }
    
    .site-search__form .site-search__field {
        width: 100%;
    }

    .site-search__suggestions-container {
        position: relative;
        top: auto;
        right: auto;
        margin-top: rem-calc(24);
        padding-left: rem-calc(20);
    }
    
    .site-search__mobile-button {
        position: absolute;
        top: rem-calc(-40);
        right: 0;
    }
    
    .site-search__suggestions-product-tiles {
        .site-search__suggestions-matches {
            padding-right: rem-calc(20);
        }
        
        .site-search__suggestions-item {
            width: 40%;
            padding-left: rem-calc(8);
            &:first-child {
                margin-left: rem-calc(12);
            }
        }
        
        .product-tile__name-price-container {
            font-size: rem-calc(16);
        }
    }
}

@include media-query(small-up-to-medium) {
    .site-search__suggestions-product-tiles {
        [data-tile-count="1.0"],
        [data-tile-count="2.0"] {
            justify-content: flex-end;
        }
    }
}

@include media-query(small) {
    .site-search__suggestions-container {
        padding-left: 0;
    }

    .site-search__suggestions-item--category:nth-child(5),
    .site-search__suggestions-item--category:nth-child(6),
    .site-search__suggestions-item--category:nth-child(7),
    .site-search__suggestions-item--category:nth-child(8) {
        display: none;
    }
}

@include media-query(medium-up) {
        .header__search {
            height: rem-calc(50);
            align-items: center;
        }

        .site-search {
            height: rem-calc(75);
            overflow: hidden;
            display: flex;
            align-items: center;
        }

        .site-search__form {
            align-items: center;
            border-bottom: rem-calc(2) solid $color-black;
            border-top: rem-calc(2) $color-black;
            display: block;
            height: auto;
            max-width: 100%;
            padding: rem-calc(20) 0 rem-calc(20);
            transition: none;
            text-align: center;
            top: calc(100% + 2px);
            width: 100%;
        }

        .focus-within .site-search__form,
        .set--has-results .site-search__form {
            height: auto;
        }

        .site-search__form-wrapper {
            max-width: rem-calc(1253);
            padding: 0;
            margin: rem-calc(20) auto rem-calc(28);
            width: 90vw;
        }

        .site-search__suggestions-container {
            width: 90vw;
            max-width: rem-calc(1253);
            margin: 0 auto;
            text-align: left;
            position: relative;
            top: 0;
        }

        .site-search__field {
            padding: rem-calc(21) rem-calc(10) rem-calc(8);
            width: 100%;
        }

        .site-search__button {
            position: absolute;
        }

        .site-search__submit-button,
        .site-search__cancel-button {
            width: rem-calc(140);
        }

        .site-search__submit-button {
            margin: 0 rem-calc(16);
        }

        .header__search-trigger {
            height: auto;
            width: auto;
        }

        .site-search__trigger-icon {
            position: unset;
        }
    }


