
.product-line-item {
    position: relative;
    margin: 0 0 rem-calc(32);

    &.product-line-item--checkout-sidebar {
        margin: 0 0 rem-calc(24);
    }

    &:last-child:not(.product-line-item--wishlist) {
        margin-bottom: rem-calc(24);

        .account-card__body & {
            margin-bottom: rem-calc(32);
        }
    }

    &.set--in-wishlist {
        .product-line-item__wishlist-add {
            display: none;
        }

        .product-line-item__wishlist-remove {
            display: inline-block;
        }
    }
}

.product-line-item--checkout-sidebar {
    .price {
        flex-wrap: nowrap;
    }
}

.product-line-item__remove {
    margin: auto rem-calc(4);
}

.product-line-item__remove .inline-prompt {
    position: fixed;
    z-index: 3;
}

.product-line-item--tooltip {
    align-items: center;
    border-top:1px solid $color-black;
    border-bottom:1px solid $color-black;
    flex-direction: row;
    margin: rem-calc(5) 0;
    padding: rem-calc(25) 0;
}

    .product-line-item__ship-date {
        background-color: $color-start-white;
        color: $color-black;
        display: inline-flex;
        flex-direction: row;
        padding: 0 rem-calc(16);
        align-items: center;

        &--minicart {
            padding: 0 rem-calc(8);
            width: 100%;
        }

        .product-line-item__ship-date-label {
            padding: rem-calc(8) 0;
        }

        .icon {
           width: rem-calc(20);
        }
    }

    .product-line-item__message--scarcity-message {
        width: fit-content;
        height: rem-calc(22);
        justify-content: center;
        align-items: center;
        padding: rem-calc(4) rem-calc(10) rem-calc(2);
        background: $color-beige;
        margin-top: rem-calc(4);
        white-space: nowrap;
    }

    .product-line-item__message--unavailable {
        width: fit-content;
        height: rem-calc(17);
        justify-content: center;
        align-items: center;
        padding: rem-calc(4) rem-calc(10) rem-calc(2);
        background: $color-error-light;
        margin-bottom: rem-calc(5);
        font-size: rem-calc(12);

        .cart__line-item & {
            @include media-query(medium-up) {
                width: auto;
                height: auto;
                align-self: flex-start;
                font-size: rem-calc(12);
            }
        }
    }

.product-line-item__details:not(.product-line-item__details--minicart) .product-line-item__info {
    display: none;
}

.product-line-item__info {
    padding: 0;

    .order-confirmation__product-list & {
        display: block;
        padding-left: rem-calc(5);
        padding-right: rem-calc(5);
    }
}

.product-line-item__image-wrapper {
    max-width: rem-calc(214);
    flex-basis: rem-calc(214);
}

.product-line-item__preorder {
    white-space: nowrap;
    .product-line-item__ship-date {
        align-items: center;
        padding: 0 rem-calc(16);
        height: rem-calc(32);
    }
}

.product-line-item--checkout-sidebar {
    .line-item__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

[data-giftcertificate-summary=gcSenderEmail],
[data-giftcertificate-summary=gcRecipientEmail] {
    word-break: break-all;
}

.product-line-item--checkout-sidebar,
.product-line-item--cart,
.product-line-item--minicart {
    .price {
        font-size: rem-calc(14);
    }
}

@include media-query(medium-up) {
    .product-line-item--order,
    .product-line-item--wishlist,
    .product-line-item--checkout-sidebar {
        .product-line-item__qty-pricing {
            margin-top: 1.25rem;
        }
    }

    .product-line-item--cart {
        .price--total {
            padding-top: rem-calc(5);
        }
    }

    .product-line-item--checkout-sidebar {
        .product-line-item__image-wrap {
            padding-bottom: 0;
        }
    }

    .product-line-item__header {
        .order-confirmation__product-list & {
            margin-bottom: rem-calc(16);
        }
    }
}

@include media-query(medium) {
    .product-line-item__qty-pricing {
        margin-top: 1rem;
    }
}

@include media-query(small-up) {
    .product-line-item__remove {
        margin: auto rem-calc(8);
    }

    .product-line-item--cart {
        padding: 0;
    }

        .product-line-item__actions {
            display: flex;
        }

            .product-line-item__action {
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
}

@include media-query(small) {
    .product-line-item--group:not(.product-line-item--checkout-sidebar) {
        padding-top: rem-calc(12);
    }

    .product-line-item__unit-price {
        .price {
            flex-direction: column;
        }

        .price__sales {
            margin-right: 0;
        }
    }

    .product-line-item__name {
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        visibility: visible;

        .product-line-item--exchange-return & {
            display: block;
        }
    }
}
