@import "~app_accelerator_core/components/tooltip";

.tooltip {
    margin-left: 0;
    vertical-align: unset;
}

.tooltip__content {
    background-color: $color-grey-1;
    font-size: rem-calc(12);
    transition: none;
    min-width: 10rem;
    padding: rem-calc(15);

    &:after, &:before {
        border: solid transparent;
        border-width: $tooltip-border-width;
        content: "";
        height: 0;
        width: 0;
    }

    &.set--bottom {
        top: calc(100% + 10px);
    }

    .set--tooltip-active & {
        display: block;
    }
}

.tooltip__content-wide {
    .tooltip__content {
        min-width: 34rem;
    }
}

.tooltip__arrow-up .tooltip__content {

    &:after, &:before {
        @include absolute(left 50% bottom 100%);
        border-bottom-color: $color-grey-1;
        margin-left: rem-calc(-10);
    }

    &.set--right {
        &:after  {
            left: auto;
            right: rem-calc(9);
        }
        &:before {
            display: none;
        }
    }
}

.tooltip__arrow-down .tooltip__content {

    &:after, &:before {
        @include absolute(left 50% top 100%);
        border-top-color: $color-grey-1;
        margin-left: rem-calc(-10);
    }
}

.tooltip__arrow-left .tooltip__content {

    &:after, &:before {
        @include absolute(right 100% top 50%);
        border-right-color: $color-grey-1;
    }
}

.tooltip__arrow-right .tooltip__content {

    &:after, &:before {
        @include absolute(left 100% top 50%);
        border-left-color: $color-grey-1;
    }
}

.tooltip__arrow-up, .tooltip__arrow-down {
    .tooltip__content:after {
        background: none;
    }
}

.tooltip--custom {
    right: -5rem !important;
    margin-right: rem-calc(15) !important;
    text-align: left;
}

.tooltip--field-label {
    .tooltip__trigger {
        display: inline;
    }
}

.tooltip__refund-terms {
    margin-left: 0;
    left: rem-calc(-4);
    top: rem-calc(-4);

    .tooltip__trigger-icon {
        font-size: rem-calc(16);
    }

    .tooltip__content {
        width: rem-calc(240);
    }
}

.tooltip__final-sale {
    height: rem-calc(16);
    margin: 0;
}

.tooltip__shipping-fee {
    margin-left: rem-calc(4);
    margin-top: rem-calc(7);
    margin-right: rem-calc(-8);
    margin-bottom: rem-calc(-8);
}

.tooltip__order-sumary {
    margin-left: rem-calc(4);
    margin-top: rem-calc(7);
    margin-right: rem-calc(-8);
    margin-bottom: rem-calc(-8);
}

@include media-query(small) {
    .tooltip--custom {
        margin-right: 2rem !important;
    }

    .tooltip__content {
        min-width: 46vw;
    }
}

@include media-query(medium) {
    .tooltip__content {
        padding: rem-calc(15);
        background-color: $color-grey-1;
    }

    .tooltip__final-sale .tooltip__content.set--tooltip-active.set--center {
        transform: translate(0, 0) scale(1);
        left: rem-calc(-65);
    }


    .set--bottom.set--center {
        transform: translate(-50%, -.5rem) scale(1);
    }

    .tooltip__final-sale .tooltip__content {

        &:after, &:before {
            @include absolute(left rem-calc(75) bottom 100%);
            border-bottom-color: $color-grey-1;
            margin-left: -0.625rem;
        }
    }
}



/*md
@no-stat

# Tooltip

## Tooltip Arrow Down

```html_example

<div class="col-4 flex-flow-row align-items-center margin-t--50 margin-b--50">
<span class="font-size--12">Tooltip Arrow Up</span>
<span class="tooltip tooltip--field-label font-size--12 tooltip__arrow-up">
<button type="button" class="tooltip__trigger link--primary" data-toggle='{"target": ".tooltip__content", "activeClass": "set--tooltip-active", "skipSelector": false}}'>
<span aria-hidden="true" focusable="false" class="tooltip__trigger-icon icon font-size--15"
data-toggle='{"target": ".tooltip__content", "parent": ".tooltip",  "activeClass": "set--tooltip-active", "auto": false, "persist": true, "event": "click"}'
>
&#xe024;
</span>
</button>
<div class="tooltip__content set--bottom set--center">
       Tooltip content comes here
</div>
</span>
</div>



```

## Tooltip Arrow Up

```html_example

<div class="col-4 flex-flow-row align-items-center margin-t--50 margin-b--50">
<span class="font-size--12">Tooltip Arrow Down</span>

<span class="tooltip tooltip--field-label font-size--12 tooltip__arrow-down">

    <button type="button" class="tooltip__trigger link--primary" data-toggle='{"target": ".tooltip__content", "activeClass": "set--tooltip-active", "skipSelector": false}}'>
        <span aria-hidden="true" focusable="false" class="tooltip__trigger-icon icon font-size--15"
        data-toggle='{"target": ".tooltip__content", "parent": ".tooltip",  "activeClass": "set--tooltip-active", "auto": false, "persist": true, "event": "click"}'
        >
                &#xe024;
        </span>
    </button>
    <div class="tooltip__content set--top set--center">
                Tooltip content comes here
    </div>
</span>

</div>
```
 */
