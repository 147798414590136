@import "~app_accelerator_core/modules/promoBanner";

.promo-banner {
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);
    height: $header-promo-height;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @include media-query(small) {
        font-size: rem-calc(12);
        height: $header-promo-height-m;
    }
}
