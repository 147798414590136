// Start of CSS is provided by Cloudinary

.cloudinary-video-container {
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
}

.video-js .vjs-text-track-display div div {
    color: orange !important;
    background-color: transparent !important;
}

// End of CSS is provided by Cloudinary
