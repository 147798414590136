
.secondary-navigation {
    position: relative;
}

.secondary-navigation__item {
  &:not(:last-child) {
      margin-bottom: 0;
  }
}

.secondary-navigation__header {
  margin-bottom: rem-calc(32);

  .secondary-navigation__welcome {
    color: $color-grey-dark;
    margin-bottom: rem-calc(8);
  }
  .secondary-navigation__name {
    display: inline-flex;
    align-items: center;
    font-size: rem-calc(32);
    line-height: rem-calc(48);
  }
  .secondary-navigation__spacer {
    margin-top: rem-calc(8);
    width: rem-calc(16);
    border-bottom: solid rem-calc(2) $color-black;
  }
}

.secondary-navigation__mobile {
  .secondary-navigation__header {
    display: none;
  }
}

.secondary-navigation__desktop.account-pages {
  display: none;
}

@include media-query(small-up) {
  .secondary-navigation__desktop.account-pages {
    display: flex;
  }
  .secondary-navigation__item {
    &:not(:last-child) {
        margin-bottom: 0;
    }
  }

  .secondary-navigation__mobile {
    display: none;
  }
}

@include media-query(small) {
  .secondary-navigation__mobile.account-landing {
    display: none;
  }
  .secondary-navigation__desktop {
    .secondary-navigation__list {
      display: none;
    }
  }
  .secondary-navigation {
      margin-bottom: rem-calc(25);
      border-bottom: solid rem-calc(1) $color-black;
  }

    .secondary-navigation__trigger {
        margin-bottom: rem-calc(20);
        position: relative;
        width: 100%;
        text-align: left;
        //font-weight: $font-weight-semibold;
    }

      .secondary-navigation__trigger-icon {
          right: 1rem;
          @include vertical-align();

          .toggle--active & {
              transform: rotate(180deg);
          }
      }

    .secondary-navigation__list-old {
        display: none;

        &.toggle--active {
            display: block;
        }
    }

      .secondary-navigation__item, .secondary-navigation__item--extra {
          padding: rem-calc(3);
      }
}
