// REF-2365 updates to fixed header
@keyframes header--sticky-nav-down {
    0% {
        margin-top: -45px;
    }
    100% {
        margin-top: 0;
    }
}

@include media-query(medium-up) {
    .header.fixed--scrolled {
        animation-name: header--sticky-nav-down;
        animation-duration: 200ms;

        .header__top-left,
        .header__top-right > *:not(.header__minicart) {
            display: none;
        }

        .header__middle {
            height: rem-calc(46);
            max-width: none;
            flex-basis: 100%;
            flex-shrink: 1;
            padding: 0;
            margin-right: rem-calc(200);
            justify-content: flex-start;

            .header-flyout {
                flex-shrink: 1;
            }

            .header-flyout__list.level-1 {
                flex-wrap: wrap;
                height: rem-calc(44);
                overflow: hidden;
            }
        }

        .header__top-right {
            align-self: auto;
            order: 1;
            margin-left: auto;
            max-width: rem-calc(60);
            padding: 0;
        }

        .header__main {
            align-items: center;
            justify-content: flex-start;
            margin: 0 auto;
            padding: rem-calc(2) rem-calc(32) 0;
            flex-wrap: nowrap;
            height: $minimized-nav-height;
        }

        .header__logo-container {
            margin-right: rem-calc(16);
            max-width: rem-calc(105);
            padding: 0;
        }

        .header__logo {
            display: block;
            height: rem-calc(18);
            margin-top: 0;
            margin-bottom: 0;
        }

        .header__logo-image {
            width: 100%;
        }

        .header-flyout__item > a, .header__search .site-search button {
            font-size: $font-size-base;
        }

        .header__utility-anchor .icon--magnifying-glass {
            height: rem-calc(16);
            margin-bottom: -2px;
            width: rem-calc(16);
        }
    }
}

.header-flyout__container {
    &.level-1 {
        color: $header-nav-color;
    }

    &.level-2 {
        color: $header-nav-panel-color;
    }

    .header-flyout__scrollable {
        [data-menu-wrap] [data-menu-item] {
            a {
                white-space: normal;
                display: inline-block;

                @include media-query(medium-up) {
                    font-size: 1rem;
                    line-height: 1.3;
                    white-space: normal;
                    margin-top: rem-calc(8);
                }
            }
        }
    }

    .header-flyout__scrollable::-webkit-scrollbar {
        display: none
    }
}

.header-flyout__close {
    color: $header-nav-panel-color;
}

.header-flyout__secondary-link:not(.header__utility-item) {
    padding: 0 rem-calc(20);
    font-size: $font-size-base;
    line-height: 1.75;
}

.header-flyout__content-tile {
    &:hover, &:focus {
        .component-actions__cta {
            text-decoration: underline;
        }
    }
}

@include media-query(medium-up-to-large) {
    .header-flyout__anchor {
        white-space: nowrap;
    }
}

@include media-query(medium-up) {
    .header-flyout {
        border-right: none;
        max-width: 100%;
    }

    .header-flyout__container {
        .header-flyout__scrollable {
            [data-menu-wrap] {
                width: rem-calc(175);
            }
        }
        .header-flyout__tile-img-container {
            height: rem-calc(220);
        }
    }

    .header-flyout__list {
        &.level-2 {
            display: flex;
            justify-content: space-evenly;
        }
    }

        .header-flyout__item {
            &.level-3 {
                font-size: 1rem;
            }
        }

        .header-flyout__anchor {
            &.level-1 {
                @include relative;

                transition-property: opacity, box-shadow;
                transition-duration: $transition-speed-fast;
                transition-timing-function: $transition-ease-default;

                &:hover:after,
                &.toggle--active:after,
                &.mega-item--active:after {
                    @include absolute(left 0 right 0 bottom .75rem);

                    content: "";
                    background: $color-black;
                    height: 2px;
                    margin: auto;
                    width: calc(100% - #{rem-calc(16)});
                }
            }

            &:not(.level-1) {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .header-flyout__anchor--parent,
        .header-flyout__anchor--list-title {
            &.level-3 {
                text-decoration: underline;
            }
        }

        .header-flyout__container {
            &.level-2 {
                z-index: 1;
                left: 0;
                right: 0;
                width: 100vw;
                padding: 1.875rem 0 1.875rem 4.375rem;
                background-color: $header-nav-panel-bg;
                border-bottom: 2px solid $color-black;
            }
        }

        .header-flyout__column-container {
            display: flex;

            .header-flyout__tile-img {
                height: 16rem;
                max-width: none;
            }
        }

            //These class names are added through Page Designer:
            .header-flyout__column--mainNavigationColumn {
                flex: 0 1 12.5rem;
                min-width: 8rem;
            }

            .header-flyout__column--mainNavigationMediaColumn {
                max-width: 100%;
                overflow: auto;
            }

            .header-flyout__column--align-right {
                margin-left: auto;
            }
            //End Page Designer class names

            .header-flyout__column,
            .header-flyout__column-media {
                &:not(:last-child) {
                    margin-right: $gutter-outer-deci;
                }
            }

            .header-flyout__column-media-container {
                display: flex;
            }

        .header-flyout__stacked-container {
            &:not(:first-child) {
                margin-top: $gutter-outer-deci;
            }
        }

    .header-flyout__inner {
        display: flex;
    }

    .header-flyout__content {
        display: flex;
        overflow: auto;
        margin-left: auto;
        flex-wrap: nowrap;
        padding: 0 rem-calc(15) 0 0;
        height: min-content;
    }

    .header-flyout__inner--stack {
        .header-flyout__content {
            margin-left: 0;
        }
    }

    .header-flyout__content-tile {
        min-width: rem-calc(165);
        padding-left: rem-calc(20);
        flex-shrink: 0;
        -webkit-flex-shrink: 0;

        &:not(:first-child) {
            margin-left: rem-calc(15);
        }
    }

    .header-flyout__inner--border {
        .header-flyout__content-tile {
            border-left: 1px solid $color-black;

            &:not(:first-child) {
                margin-left: rem-calc(20);
            }
        }
    }

    .header-flyout__inner--divider {
        .header-flyout__content-tile {
            &:first-child {
                border-left: 1px solid $color-black;
            }
        }
    }
}

@include media-query(medium) {
    $hamburger-size: calc(100vw - #{rem-calc(44)});

    .header-flyout__overlay {
        opacity: 0;
        transition: opacity $transition-speed-medium $transition-ease-default;
    }

    .header-flyout {
        --flyout-height: 100vh;
        z-index: 1;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: $hamburger-size;
        height: calc(var(--flyout-height) - #{$header-height-m});
        top: $header-height-m;
        left: 0;
        background-color: $header-nav-panel-bg;
        overflow: hidden;
        transform: translateX(-100%);
        @include set-invisible();

        &:not(.flyout-toggle--active) {
            transform: translateX(-100%);
            @include set-invisible();
        }

        &.animate-out {
            transition: transform $transition-speed-fast $transition-ease-bezier;
            @include set-visible();
        }

        &.flyout-toggle--active {
            @include set-visible();

            overflow: visible;
            transform: translateX(0);
            transition: transform $transition-speed-medium $transition-ease-bezier;

            & + .header-flyout__overlay {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100vw;
                height: 100vh;
                background: $color-white;
                opacity: 0.7;
            }

            .header-flyout__anchor.level-1,
            .header-flyout__secondary-link {
                transform: translateX(0);
            }

            .header-flyout__anchor.level-1 {
                .icon--caret, .icon--mobile-nav {
                    width: rem-calc(24);
                    height: rem-calc(24);
                }
            }
        }
    }

        .header-flyout__head {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
        }

        .header-flyout__list {
            &.level-1 {
                display: flex;
                flex-direction: column;
            }

            &.level-2  {
                padding: rem-calc(12) rem-calc(24);

                .header-flyout__item-block {
                    padding-left: rem-calc(24);

                    ul {
                        gap: rem-calc(4);
                    }

                    a {
                        margin: 0;
                        line-height: 2em;    
                    }
                }

                .header-flyout__item--space-above {
                    margin-top: rem-calc(16);
                }
            }
        }

        .header.fixit--active .header-flyout__head,
        .header--no-top-content .header-flyout__head {
            padding-top: 0;
        }

            .header-flyout__close {
                @include absolute(top 0 right 0);

                z-index: 2;
                padding: rem-calc(14) rem-calc(16);
            }

            .header--no-top-content .header-flyout__close {
                padding-bottom: rem-calc(13);
                padding-top: rem-calc(13);
            }

        .header-flyout__container {
            &.level-1 {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                max-height: 100%;
            }

            &:not(.level-1) {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $header-nav-panel-bg;
                transition: transform $transition-speed-fast $transition-ease-bezier, opacity $transition-speed-fast $transition-ease-bezier 1s;

                &.mega-item--active {
                    transform: translateX(0);
                    transition: transform $transition-speed-medium $transition-ease-bezier;
                    @include set-visible();
                }

                &:not(.mega-item--active) {
                    transform: translateX(-100%);
                    opacity: 0;
                }
            }
        }
        .header-flyout__item {
            &.level-1 {
                padding: rem-calc(8) rem-calc(12) rem-calc(8) rem-calc(24);
                border-bottom: rem-calc(1) solid $color-grey-1;

                &.header-flyout__item-sustainability {
                    border: none;
                    padding: 0 0 0 rem-calc(24);
                    margin: rem-calc(48) 0 rem-calc(16);
                    order: 1;

                    a.level-1  {
                        justify-content: flex-start;
                        color: $color-grey-dark;    
                        font-size: rem-calc(14);
                        align-items: flex-end;
                    }
                }
            }
        }

            .header-flyout__scrollable {
                overflow-y: auto;
                overflow-x: hidden;
                height: 100%;
            }

            .header.fixit--active .header-flyout__scrollable,
            .header--no-top-content .header-flyout__scrollable {
                padding-top: 0;
            }

            .header-flyout__anchor {
                display: flex;
                align-items: center;
            }
            
            .header-flyout__back {
                display: flex;
                align-items: center;
                padding: rem-calc(8) rem-calc(12) rem-calc(8) rem-calc(24);
                border-bottom: rem-calc(1) solid $color-grey-1;
                color: $color-grey-dark;
                width: 100%;
                position: sticky;
                top: 0;
                background-color: $color-white;
                z-index: 2;

                .icon {
                    width: rem-calc(16);
                    height: rem-calc(16);
                }
            }

            .header-flyout__anchor.level-1:not(.header__utility-anchor--search),
            .header-flyout__secondary-link {
                transform: translateX(-5rem);
                transition-property: transform;
            }

            .header-flyout__tile-img {
                width: 100%;
            }

        .header-flyout__secondary {
            padding-left: rem-calc(24);
            margin-bottom: rem-calc(40);
        }

        .header-flyout__item--main {
            margin-top: 1rem;
        }

    .header-flyout__column-start {
        margin-bottom: rem-calc(20);
    }

    .header-flyout__content .content-tile__aspect-ratio--inherit .header-flyout__tile-img,
    .header-flyout__content .content-tile__aspect-ratio--natural .header-flyout__tile-img {
        height: auto;
    }

    .header-flyout__content .content-tile__aspect-ratio--wide {
        @include aspect-ratio(7, 5, true);
    }

    // Mobile Nav content - Default layout (1 column grid)

    .header-flyout__content {
        display: grid;
        gap: rem-calc(24) rem-calc(16);
        margin-bottom: rem-calc(24);
        padding: 0 rem-calc(24);

        .content-tile__content-wrap {
            padding-bottom: 0;
        }

        .content-tile__cta {
            max-width: 100%;
        }
    }

    // 1. Layout type: Horizontal (2 column grid)

    .header-flyout__content--horizontal {
        grid-template-columns: 1fr 1fr;

        .header-flyout__content-tile {
            white-space: normal;
        }
    }

    // 2. Layout type: Vertical - inherits default
    // 3. Layout type: Mixed - inherits default

    // 4. Layout type: Grid (1 column and 2 column rows alternate)

    .header-flyout__content--grid {
        grid-template-columns: 1fr 1fr;

        .header-flyout__content-tile {
            &:nth-child(3n - 2) {
                grid-column: span 2;
            }
        }
    }
}

@include media-query(large-up) {
    .header-flyout__tile .content-tile__cta {
        font-size: 1rem;
        line-height: 1.3em;
    }
}
