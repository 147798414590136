@mixin scrollbar($track: $color-white, $thumb: $color-black, $thumb-width: rem-calc(2)) {
    &::-webkit-scrollbar {
        height: rem-calc(2);
        width: $thumb-width;
    }

    &::-webkit-scrollbar-track {
        background: $track;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb;
        border-radius: calc($thumb-width * 2);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background: $thumb;
    }
}
