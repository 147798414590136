
.consent-notification {
    @include fixed(bottom 0 left 0 );

    z-index: 2;
    transform: translateY(0);

    transition-property: opacity, visibility, transform;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-out;

    &:not(.toggle--active) {
        transform: translateY(100%);
        @include set-invisible();
    }
}

.tracking-consent {
    margin: 0 0 rem-calc(14);
    font-size: rem-calc(12);
}

@include media-query(small-up) {
    .consent-notification {
        margin: rem-calc(30);
        padding: rem-calc(24) rem-calc(30) rem-calc(30);
        width: rem-calc(375);
    }
}

@include media-query(small) {
    .consent-notification {
        padding: rem-calc(16) rem-calc(16) rem-calc(32);
        
        .body--fixed-scroll & {
            z-index: 1;
        }
    }
}
