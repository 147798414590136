.modal--sizequide {
    .window-modal__content {
        max-width: rem-calc(728);
        padding: rem-calc(32) 0 0 0;
        margin-top: rem-calc(100);
    }

    &::-webkit-scrollbar{
        display:none
    }
}
.size-guide.row {
    margin-left: 0;
    margin-right: 0;
}

.size-guide__nav {
    display: flex;
    border: 1px solid $color-black;
}

.conversion-table-contents {
    .size-guide__group-wrap, .size-guide__contents .size-guide__content {
        border: 0;
    }

    .size-guide__group-wrap {
        padding: rem-calc(24) rem-calc(32);
    }
}

.size-guide__group-wrap {
    font-size: rem-calc(14);
    display: flex;
    flex-direction: row;
    padding: rem-calc(24) rem-calc(16);
    border-right: 1px solid $color-black;
    border-left: 1px solid $color-black;
}

.size-guide__group {
    display: flex;
    flex-direction: row;
}

svg.size-guide__icon, 
.size-guide__icon{
    display: none;
    padding: 0 rem-calc(4);
    font-size: rem-calc(10);
    width: rem-calc(18);
    height: rem-calc(12);
}

.size-guide__img {
    border-radius: 20px;
    margin-bottom: rem-calc(24);
}

.size-guide__filter-trigger {
    align-items: center;
    display: flex;
    text-align: left;
    margin-right: rem-calc(8);
    color: $color-grey-dark;

    &.active {
        border-bottom: 1px solid $color-black;
        color: $color-black;
    }
}

.size-guide__wrap {

    .table--data-grid {
        width: 100%;
        thead {
            th, td {
                text-align: center;
                border: 0;

                &:first-child {
                    padding-left: rem-calc(8);
                }
            }
        }
        
        tbody {
            td, th {
                border: 0;
                height: 40px;
                text-align: center;

                &:first-child {
                    padding-left: rem-calc(8);
                }
            }  
            
            tr:nth-child(odd) {
                background-color: $color-grey-1;
            }             
        }
    }   
}
  
.size-guide__contents {
    display: inline-block;
    padding-bottom: rem-calc(24);
    padding-left: 0;
    padding-right: 0;

    .size-guide__content {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: rem-calc(24);
        border: 1px solid $color-black;
        border-top: 0;
    }
}

.size-guide__content {
    display: none;
    padding-right: rem-calc(24);
    padding-left: rem-calc(32)
}

.size-guide__content.active {
    display: block;
}

.size-guide__content.padding--0 {
    padding: 0;
}

.size-guide__wrap .tab-nav {
    margin-bottom: 0;
}

.size-guide__wrap .tab-nav__item {
    padding: rem-calc(16);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    &.toggle--active {
        background-color: $color-black;
        color: $color-white;
    }
}

.size-guide .tab-nav {
    justify-content: end;
}

.size-guide__table-padding {
    margin-top: rem-calc(24);
    padding: 0 0 rem-calc(24);
}

@include media-query(small) {
    .modal--sizequide {
        .window-modal__content {
            padding-top: rem-calc(82);
        }
    }

    .fit-tip-content {
        width: 100%;
    }

    .size-guide {
        .image-container {
            padding: 0 rem-calc(48);
        }
    }

    .size-guide__table-padding {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .size-guide__table-padding .table {
        display: inline-table;
    }

    .window-modal.modal--sizequide .window-modal__content {
        width: 100%;
        border: none;
    }

    .size-guide__content {
        padding: rem-calc(24);
        overflow: hidden;
    }

    .size-guide__panel {
        width: 100%;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        .table--data-grid {
            min-width: 100%;
        }
    }

    .table--size-conversion tr td {
        width: rem-calc(50);
    }

    .table--size-conversion tr th {
        width: rem-calc(40);
    }

    .size-guide__table-padding {
        padding: 0;
    }

    .size-guide__wrap .tab-nav {
        justify-content: start;
    }

    .size-guide__contents {
        padding-right: 0;
        padding-bottom: 0;

        .size-guide__content {
            border: 0;
        }
    }

    .size-guide__group-wrap {
        border: 0;
        padding: rem-calc(24) rem-calc(32);
    }

    .table--size-guide-cg {
        width: auto;
        min-width: 100%;
    }
}

@include media-query(small-up) {
    .size-guide__nav {
        width: 50%;
    }

    .fit-tip-content {
        width: 50%;
    }
}




/*md
@no-stat

# Sizeguide

```html_example

   <div class="size-guide row" data-size-guide-toggle>
                        <div class="size-guide__contents col-12 col-md-6">
                            <div class="size-guide__group-wrap col-12">
                                <div class="size-guide__group" data-filter-component="filter-group">
                                    <button class="size-guide__filter-trigger font-size--12 d-font-size--14 active" data-filter-trigger  data-filter-by="a,b">Regular
                                        <span class="size-guide__icon icon" title="xe013">&#xe013;</span>
                                    </button>
                                    <button class="size-guide__filter-trigger font-size--12 d-font-size--14" data-filter-trigger   data-filter-by="a1,b1">Petite
                                        <span class="size-guide__icon icon" title="xe013">&#xe013;</span>
                                    </button>
                                </div>
                                <div class="size-guide__group" data-filter-component="filter-group">
                                    <button class="size-guide__filter-trigger font-size--12 d-font-size--14 active" data-filter-trigger   data-filter-by="b,a">In
                                        <span class="size-guide__icon icon" title="xe013">&#xe013;</span>
                                    </button>
                                    <button class="size-guide__filter-trigger font-size--12 d-font-size--14" data-filter-trigger   data-filter-by="b1,a1">Cm
                                        <span class="size-guide__icon icon" title="xe013">&#xe013;</span>
                                    </button>
                                </div>
                            </div>

                            <div class="size-guide__content col-12  font-size--12 d-font-size--14 active" data-filter-content="a,b">
                                <div class="size-guide__panel size-guide__regular size-guide__regular--in"><table class="table table--data-grid"><thead><tr><th>US size</th><th>Bust</th><th>Waist</th><th>Hip</th></tr></thead><tbody><tr><td>0</td><td>32</td><td>25</td><td>37</td></tr><tr><td>2</td><td>33</td><td>26</td><td>38</td></tr><tr><td>4</td><td>34</td><td>27</td><td>39</td></tr><tr><td>6</td><td>35</td><td>28</td><td>40</td></tr><tr><td>8</td><td>36.5</td><td>29.5</td><td>41.5</td></tr><tr><td>10</td><td>38.5</td><td>31.5</td><td>43.5</td></tr><tr><td>12</td><td>40.5</td><td>33.5</td><td>45.5</td></tr><tr><td>14</td><td>44</td><td>37.5</td><td>47.5</td></tr><tr><td>16</td><td>46</td><td>39.5</td><td>49.5</td></tr><tr><td>18</td><td>48</td><td>41.5</td><td>51.5</td></tr><tr><td>20</td><td>50</td><td>43.5</td><td>53.5</td></tr><tr><td>22</td><td>52</td><td>45.5</td><td>55.5</td></tr><tr><td>24</td><td>54</td><td>47.5</td><td>57.5</td></tr></tbody></table></div>
                            </div>

                            <div class=" size-guide__content col-12 font-size--12 d-font-size--14  " data-filter-content="a1,b1">
                                <div class="size-guide__panel size-guide__regular size-guide__regular--cm "><table class="table table--data-grid"><thead><tr><th>US size</th><th>Bust</th><th>Waist</th><th>Hip</th></tr></thead><tbody><tr><td>0</td><td>81</td><td>64</td><td>94</td></tr><tr><td>2</td><td>84</td><td>66</td><td>97</td></tr><tr><td>4</td><td>86</td><td>69</td><td>99</td></tr><tr><td>6</td><td>89</td><td>71</td><td>102</td></tr><tr><td>8</td><td>93</td><td>75</td><td>105</td></tr><tr><td>10</td><td>98</td><td>80</td><td>110</td></tr><tr><td>12</td><td>103</td><td>85</td><td>116</td></tr><tr><td>14</td><td>112</td><td>95</td><td>121</td></tr><tr><td>16</td><td>117</td><td>100</td><td>126</td></tr><tr><td>18</td><td>122</td><td>105</td><td>131</td></tr><tr><td>20</td><td>127</td><td>110</td><td>136</td></tr><tr><td>22</td><td>132</td><td>116</td><td>141</td></tr><tr><td>24</td><td>137</td><td>120.5</td><td>146</td></tr></tbody></table></div>
                            </div>

                            <div class=" size-guide__content col-12  font-size--12 d-font-size--14" data-filter-content="b,a">
                                <div class="size-guide__panel size-guide__petite size-guide__petite--in"><table class="table table--data-grid"><thead><tr><th>US size</th><th>Bust</th><th>Waist</th><th>Hip</th></tr></thead><tbody><tr><td>0P</td><td>31</td><td>25</td><td>35.5</td></tr><tr><td>2P</td><td>32</td><td>26</td><td>36.5</td></tr><tr><td>4P</td><td>33</td><td>27</td><td>37.5</td></tr><tr><td>6P</td><td>34</td><td>28</td><td>38.5</td></tr><tr><td>8P</td><td>35.5</td><td>29.5</td><td>40</td></tr><tr><td>10P</td><td>37.5</td><td>31.5</td><td>42</td></tr><tr><td>12P</td><td>39.5</td><td>33.5</td><td>44</td></tr></tbody></table></div>
                            </div>

                            <div class=" size-guide__content col-12 font-size--12 d-font-size--14 " data-filter-content="b1,a1">
                                <div class="size-guide__panel size-guide__petite size-guide__petite--cm"><table class="table table--data-grid"><thead><tr><th>US size</th><th>Bust</th><th>Waist</th><th>Hip</th></tr></thead><tbody><tr><td>0P</td><td>79</td><td>64</td><td>90</td></tr><tr><td>2P</td><td>81</td><td>66</td><td>93</td></tr><tr><td>4P</td><td>84</td><td>69</td><td>95</td></tr><tr><td>6P</td><td>86</td><td>71</td><td>98</td></tr><tr><td>8P</td><td>90</td><td>75</td><td>102</td></tr><tr><td>10P</td><td>95</td><td>80</td><td>107</td></tr><tr><td>12P</td><td>100</td><td>85</td><td>112</td></tr></tbody></table></div>
                            </div>

                            <p class="font-size--12 text-color--grey-4 col-12  padding-l--20">The size guide serves as a general guideline for helping you find your size in Ref. Exact measurements vary with each style.</p>
                        </div>
                        <div class="size-guide__content col-12 col-md-6 padding--0 active">
                            <img alt="Numeric Size Guide" src="https://thereformation-weblinc.netdna-ssl.com/assets/workarea/storefront/size_guide_dress-f2bd04fbbe8cd85a73c4d9f49547978f4d672836faf86ae7e748e954e14302ff.png">
                        </div>
                    </div>

```

*/
