
.product-attribute__list {
    //Remove bottom margin to offset the difference added by the individual
    //.product-attribute elements.
    margin-bottom: rem-calc(8);

    .set--loading & {
        pointer-events: none;
    }

    .pdp__details--not-available & {
        margin-bottom: rem-calc(24);
    }

    //This specifity is intentional so that it is easier to separate
    //different types of "product-attribute__list" container.
    //For example, one may prefer to have a different layout for QuickAdd, Bundles, etc.
    .product-attribute {
        &:not(.product-attribute--first) {
            margin-top: rem-calc(12);
        }

        &.product-attribute--sizepicker {
            margin-top: rem-calc(12);
        }
    }

    .product-attribute--type-anchor {
        .product-attribute__contents {
            margin: rem-calc(-2);
        }
    }

    .product-attribute__anchor {
        margin: rem-calc(2);
    }

    .product-attribute__swatch {
        text-indent: -9999999px;
        margin-left: rem-calc(2.5);
        margin-right: rem-calc(5.5);
        margin-bottom: rem-calc(16);
        &.selected {
            pointer-events: none;
        }

        &.swatch--on-sale {
            order: -1;

            &:before {
                content: '';
                display: block;
                width: rem-calc(4);
                height: rem-calc(4);
                border-radius: 50%;
                position: absolute;
                top: calc(100% + #{rem-calc(4)});
                background-color: $color-error;
            }
        }
    }

    .show-oos-indicators {
        .swatch--unavailable:not(.selected) {
            &:after{
                border: rem-calc(1) dashed $color-grey-dark;
            }

            &:hover {
                &:after{
                    border: rem-calc(1) solid  $color-black;
                }
            }
        }
    }
}

.product-attribute__scarcity-message {
    margin-left: auto;
    font-family: $font-family-heavy;
}

.product-attribute--color .product-attribute__label {
    width: 100%;
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.product-availability__label {
    margin-right: .25em;
}

.promotion-badge-container {
    position: absolute;
    right: 0
}

#attn-inline-price-trigger-btn {
    text-align: center;
    background-color: $color-beige;
    padding: rem-calc(12);
    width: 100%;
    margin-bottom: rem-calc(8);
}

.product-promotions__item {
    word-break: break-word;

    &.promo-badge {
        font-size: rem-calc(12);
        font-family: $font-family-heavy;
    }
}

.product-add__button {
    &.button--apple-pay {
        &, &:hover, &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }

    &[disabled],
    &.disabled {
        cursor: auto;
    }
}

.product-add__estimated-date {
    &:not(:empty),
    &.estimated-ship--viewed {
        display: inline-block;
        text-align: center;
        margin: rem-calc(8) 0;
        min-height: rem-calc(16);
    }
}

@include media-query(small) {
    .body--fixed-scroll #attentive_overlay {
        display: none;
    }
}

/*------------------------------------*\
  #ADD-TO-CART BUTTON
\*------------------------------------*/

.product-add__button {
    &[data-button-state="loading"],
    &[data-button-state="complete"] {
        pointer-events: none;

        .product-add__button_text {
            display: none;
        }
    }
}

.product-add__button_loading-dots {
    position: relative;
    width: rem-calc(5);
    height: rem-calc(5);
    border-radius: 50%;
    background-color: $color-white;
    transform: translateX(rem-calc(-10));
    display: none;

    .button--primary-outline & {
        background-color: $color-black;
    }

    [data-button-state="loading"] & {
        display: inline-flex;
    }

    @media (hover: hover) {
        .product-add__button:hover:not([disabled]):not(.disabled) & {
            background-color: $color-black;
        }

        .button--primary-outline:hover:not([disabled]):not(.disabled) & {
            background-color: $color-white;
        }
    }

    &:before,
    &:after {
        content: '';
        display: inline-flex;
        width: rem-calc(5);
        height: rem-calc(5);
        border-radius: 50%;
        position: absolute;
        background-color: $color-white;

        .button--primary-outline & {
            background-color: $color-black;
        }

        @media (hover: hover) {
            .product-add__button:hover:not([disabled]):not(.disabled) & {
                background-color: $color-black;
            }

            .button--primary-outline:hover:not([disabled]):not(.disabled) & {
                background-color: $color-white;
            }
        }
    }

    &:before {
        right: rem-calc(-10);
        animation: loading-dot1 1s infinite linear;
    }

    &:after {
        right: rem-calc(-20);
        animation: loading-dot2 1s infinite linear;
    }
}

.product-add__button_checkmark {
    line-height: 1em;
    display: none;

    [data-button-state="complete"] & {
        display: inline-flex;

        .product-add__button_text--added {
            display: none;
        }
    }

    .icon {
        width: rem-calc(20);
        height: rem-calc(20);

        @media (hover: hover) {
            .product-add__button:hover:not([disabled]):not(.disabled) & {
                fill: $color-black;
            }
        }
    }
}
