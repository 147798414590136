.subscription-banner__action {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: rem-calc(112);
}

.subscription-banner__icon {
    margin-right: rem-calc(6);
}

.subscription-cta-form {
    width: 100%;

    .invalid-feedback.set--visible {
        text-align: left;
    }

    .form-control.is-invalid {
        background-color: $color-white;
    }

    .input--secondary {
        position: absolute;
        right: 0;
        color: black;
        transform: rotate(180deg);
        .arrow-submit {
            height: rem-calc(26);
            width: rem-calc(26);
        }
    }
}

.subscription-banner {
    width: 100%;

    .row {
        position: relative;
    }

    &__form {
        width: 100%;
    }

    .invalid-feedback {
        position: absolute;
        top: 100%;
        white-space: nowrap;
    }

    .subscription-banner__control[data-floating-label] {
        margin-bottom: 0;

        .form-control-label {
            display: none;
            left: rem-calc(16);
            top: rem-calc(8);
        }

        &:not(.set--floating-label) {
            .form-control {
                &::placeholder {
                    color: $color-grey-mid;

                    [lang="fr"] & {
                        font-size: rem-calc(14);
                    }
                }

                //This seems to be required for IE.
                &:-ms-input-placeholder {
                    color: $color-grey-mid;
                }
            }
        }

        &.set--floating-label {
            .form-control {
                &::placeholder {
                    color: transparent;
                }

                //This seems to be required for IE.
                &:-ms-input-placeholder {
                    color: transparent;
                }
            }

            .subscription-banner__field {
                padding: rem-calc(12) rem-calc(16) 0;
            }
        }
    }

    .subscription-banner__control .subscription-banner__field {
        width: 100%;
        background-color: transparent;
        padding: rem-calc(4) rem-calc(16) 0;
        border-right-width: 0;

        &::placeholder {
            color: #000000;
        }
    }

    .subscription-banner__control .form-control-label::after {
        display: none;
    }

    .subscription-banner__control.set--floating-label  .form-control-label {
        display: flex;
    }
}

.subscription-banner--clp {
    padding: 0 rem-calc(20);

    .subscription-banner__control {
        margin-bottom: rem-calc(16);
        margin-top: 0;
    
        .subscription-banner__field {
            border: none;
            border-bottom: 1px solid $color-black;
            padding-left: 0;
            width: 100%;
            background-color: transparent;
        }

        .form-control-label {
            left: 0;
        }

        &.set--floating-label {
            .form-control-label {
                top: rem-calc(-4);
            }
        }
    }

    .subscription-banner__action {
        height: rem-calc(56);
    }
}

@include media-query(small) {
    .subscription-banner__field {
        font-size: 1rem !important;
    }

    .subscription-banner--clp {
        flex-wrap: wrap;
    }
}

@include media-query(small-up) {
    .subscription-banner--clp {
        padding: 0 rem-calc(64);
        background-color: $color-grey-1;
        align-items: center;
    }

    .subscription-banner--clp {
        padding: 0 rem-calc(64);
        background-color: $color-grey-1;
        align-items: center;
    }

    .subscription-cta-form {
        width: rem-calc(400);
    }
}

@include media-query(medium-up) {
    .subscription-banner {
        max-width: rem-calc(432);
    }
}
