@import "./setup/import";

/*------------------------------------*\
  #THIRD-PARTY-LIBS
\*------------------------------------*/
@import "./lib/carousels";
@import "~core/lib/fixit";
@import "~core/lib/videojs";

/*------------------------------------*\
  #BASE
\*------------------------------------*/
@import "./base/helpers";
@import "./base/overlays";

/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/
@import "./components/header/headerMain";
@import "~app_accelerator_core/components/video";
@import "./components/footer/footerMain";
@import "./components/form-switch";
@import "./components/tabNav";
@import "./components/inlinePrompt";
@import "./components/toggleBox";
@import "./components/utilityOverlay";
@import "./components/product/swatches";

//Shared Product-related styles for QuickView, QuickAdd, and PDP.
@import "./pages/product/productCommons";
@import "./components/product/productLineItems";

@import "./components/tooltip";
@import "./components/notifications";
@import "./components/consentTracking";
@import "./components/secondaryNavigation";
@import "./components/tables";
@import "./components/cloudinary";

/*------------------------------------*\
  #MODULES
\*------------------------------------*/
@import "./modules/moduleCommonsBtf";
@import "./modules/productList";
@import "./modules/promoBanner";
@import "~app_accelerator_core/modules/modals";
@import "~app_accelerator_core/modules/quickView";
@import "./modules/quickAdd";
@import "./modules/modals";
@import "./modules/sizeGuide";
@import "~app_accelerator_core/modules/einsteinRecommender";
@import "./modules/subscriptionBanner";

@import "pages/account/emailPreferences";

@import "~app_accelerator_core/modules/pageMotion";
@import "~app_accelerator_core/modules/contentGrid";
@import "~app_accelerator_core/modules/contentOverlay";
@import "~app_accelerator_core/modules/descriptiveCard";
@import "~app_accelerator_core/modules/mediaCollection";
@import "~app_accelerator_core/modules/categoryTabs";
@import "~app_accelerator_core/modules/categoryNavigation";
@import "~app_accelerator_core/modules/htmlBlock";
@import "~app_accelerator_core/modules/iconTile";
@import "~app_accelerator_core/modules/shoppableModule";
@import "./modules/shoppableModule";
