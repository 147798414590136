.footer {
    margin: 0;
    overflow: hidden;
}

    .footer__body {
        padding: 0 rem-calc(32);
    }

    .footer__main-content {
        gap: rem-calc(48);
    }

    .footer__nav {
        grid-template-columns: repeat(3, 1fr);

        .link {
            [lang="fr"] & {
                font-size: rem-calc(14);
            }    
        }
    }

    .footer__primary {
        width: 100%;
        flex-grow: 1;
    }

    .footer__icons {
        a:hover {
            color: $color-grey-mid;
        }
    }

    .footer-links__list.footer-additional-copy {
        flex-wrap: wrap;
        row-gap: rem-calc(8);
    }    

@include media-query(small) {
    .footer__nav {
        grid-template-columns: repeat(2, 1fr);
        row-gap: rem-calc(24);
        white-space: nowrap;
    }
}

@include media-query(xxsmall) {
    .footer__nav {
        grid-template-columns: repeat(1, 1fr);
    }
}

@include media-query(medium) {
    .footer__info-wrap {
        margin-top: rem-calc(32);
    }

    .footer__main-content {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

@include media-query(medium-up) {
    .footer__main-content {
        gap: rem-calc(64);
    }

    .footer__primary {
        max-width: rem-calc(432);
    }
}
