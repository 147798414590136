.module-carousel__item.slick-slide {
    height: auto;
}

.carouselGrid-row {
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
}

.module-carouselGrid {
    max-width: 50%;

    .product-tile__body {
        text-align: center;
        margin-top: rem-calc(12);

        .price {
            display: none;;
        }
    }

    .slick-slide {
        position: relative;
    }

    .slick-slide[data-position]:not(.slick-cloned)::after {
        $index: attr(data-position);
        $endindex: attr(data-position-last);
        content: $index "/" $endindex;
        position: absolute;
        top: rem-calc(4);
        right: rem-calc(8);
        z-index: 1;
        font-size: rem-calc(14);
    }

    .product-tile:hover .product-tile__wishlist {
        display: none;
    }

    .slick-arrow {
        top: 0;
        bottom: rem-calc(56);
        margin: auto;
        width: rem-calc(32);

        .icon {
            height: rem-calc(16);
        }

        &.slick-prev {
            display: none !important;
        }
    }

    @include media-query(small-up) {
        .slick-arrow {
            .icon {
                height: rem-calc(20);
            }
        }
    }
}

.carouselGrid-container {
    background-color: $color-grey-1;
    padding: 0;

    @include media-query(small-up) {
        padding: rem-calc(36);
    }

    @include media-query(medium-up) {
        padding: rem-calc(72);
    }

    @include media-query(large-up) {
        padding: rem-calc(72) rem-calc(96);
    }

    .anchor-product {
        &.text-color-white {
            .product-tile__body {
                color: $color-white;
            }
        }
    }

    .product-tile__body {
        text-align: center;
        width: 100%;
        max-height: rem-calc(28);

        .price {
            display: none;
        }
    }

    .product-tile__swatches {
        display: none;
    }

    .product-tile:hover .product-tile__wishlist {
        display: none;
    }

    &.text-color-white {
        .product-tile__body {
            color: $color-white;
        }
    }

    .anchor-product {
        .product-tile__body {
            position: absolute;
            bottom: 0;
        }
    }

    .button--secondary {
        background-color: $color-white;
        color: $color-black;
        border: 1px solid $color-black;

        &:hover, &:hover:not([disabled]):not(.disabled) {
            background-color: $color-black;
            color: $color-white;
            opacity: 1;
        }
    }

    @include media-query(small) {
        .product-tile__name-price-container {
            display: block;

            .product-tile__name {
                font-size: rem-calc(14);
            }
        }

        .component-actions__cta {
            display: flex;
            justify-content: center;
            margin: rem-calc(4);
        }
    }
}

.grid-carousel-header {
    margin: rem-calc(32) auto;
    width: 100%;
    text-align: center;

}

@include media-query(small-up) {
    .module-container.sticky-enabled {
        overflow: unset;
    }

    .module-grid__item.sticky-enabled {
        position: sticky;
        top: 70px;
        height: 100%;
        flex-grow: 1;
        flex-shrink: 1;
    }
}
