.notification {
    @include relative;

    margin-bottom: rem-calc(20);
    padding: rem-calc(15);

    &--error {
        background: $color-error-light;
    }

    &--standard {
        background: $color-quickadd-bg;
    }

    &--has-icon {
        padding-left: rem-calc(40);
    }

    &--centered {
        margin-left: auto;
        margin-right: auto;
        max-width: 35rem;
    }

    &--cart-oos {
        background: $color-sand;
        padding: rem-calc(12) rem-calc(8);
        margin-bottom: rem-calc(48);

        @include media-query(small) {
            margin-bottom: rem-calc(24);
        }

        &:has(+ .notification) {
            margin-bottom: rem-calc(10);
        }
    }
}

    .notification__title {
        font-size: rem-calc(16);
        margin: 0 0 rem-calc(8);
    }

    .notification__message {
        font-size: rem-calc(12);
        margin-bottom: rem-calc(15);
    }

    .notification__message_inline {
        font-size: rem-calc(12);
    }

    .notification__link {
        font-size: rem-calc(13);

        &:hover {
            border-color: transparent;
        }
    }

    .notification__icon-inline {
        width: rem-calc(40);
        height: rem-calc(40);
        flex-shrink: 0;

        .icon--exclamation {
            width: rem-calc(22);
            height: rem-calc(22);
        }
    }

    .notification__content {
        margin-top: auto;
        margin-bottom: auto;

        @include media-query(small) {
            display: flex;
            flex-direction: column;
        }
    }

    .notification__icon {
        @include absolute(top rem-calc(10) left rem-calc(15));

        font-size: rem-calc(20);
    }

    .notification__icon-dismiss.icon {
        @include absolute(top rem-calc(14) right rem-calc(15));

        cursor: pointer;
        height: rem-calc(11);
        width: rem-calc(11);
    }

.notification__inline {
    background: $color-error-light;
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    padding: rem-calc(5);

    &--size-small {
        font-size: rem-calc(9);
        line-height: rem-calc(12);
        padding: rem-calc(5) rem-calc(3);
    }
}

.notification__plierror {
    font-size: rem-calc(12);
    color: #930000;
}

.notification--sfs-exclusion {
    margin-bottom: 0;

    .notification__title {
        font-size: rem-calc(12);
        font-family: $font-family-heavy;
        margin-bottom: rem-calc(8);
    }

    .notification__link {
        font-size: rem-calc(12);
        margin-top: rem-calc(8);
    }

    .notification__icon {
        top: rem-calc(12);
        left: rem-calc(12);
        width: rem-calc(24);
        height: rem-calc(24);
    }

    .icon--exclamation {
        width: rem-calc(24);
        height: rem-calc(24);
    }
}
