.shoppable-module {
    .price {
        justify-content: flex-start;
    }
}

.shoppable-module__pin-trigger {
    border: none;
    box-shadow: none;
    background-color: $color-white;
}

.shoppable-module__pin-content {
    text-align: left;
    white-space: normal;
    border-radius: 0;
    box-shadow: none;
    max-width: 100%;

    &.set--tooltip-wide {
        min-width: rem-calc(175);
    }
}

.shoppable-module__pin-content-anchor {
    padding: rem-calc(8) rem-calc(12);
}
