.table--data-grid {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  @include media-query(small) {
    width: auto;
  }

  thead {
    th, td {
      border: solid $color-black;
      border-width: 0 0 rem-calc(1);
      padding: rem-calc(8);
      text-align: left;   
      font-weight: normal;
    }
  }

  tbody {
    td, th {
      border: solid $color-black;
      border-width: 0 rem-calc(1) rem-calc(1) 0;
      padding: rem-calc(8);
      white-space: nowrap;
      font-weight: normal;
    }   
  }

  tr {
    border-spacing: 0;
  }

  &:not(.table--size-guide-cg) {
    th:first-child, td:first-child {
        padding-left: 0;
    }
    
    th:last-child, td:last-child {
        border-right: 0;
    }
  }
}
